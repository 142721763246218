import styled from '@mui/material/styles/styled'
import { LinkAsButton } from '@components/UI/Button'

export const ProductSoldoutContainer = styled('div', {
  name: 'ProductSoldout',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    width: '80%',
  },

  [theme.breakpoints.up('lg')]: {
    width: '65%',
  },
}))

export const StyledSoldOutBrandIconWrapper = styled('div', {
  name: 'Soldout',
  slot: 'BrandIconWrapper',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}))

export const StyledSoldOutCTA = styled(LinkAsButton, {
  name: 'Soldout',
  slot: 'CTA',
})(({ theme }) => ({
  color: theme.palette.text.light.primary,
  width: 'auto',
}))
export const SoldOutImagePlaceholderContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.light.secondary,
  padding: `${theme.spacing(5)} 0`,
  margin: `${theme.spacing(4)} 0`,

  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(21),
    marginRight: theme.spacing(19.5),
    height: 336,
  },
}))

export const SoldOutTitle = styled('div')(({ theme }) => ({
  fontSize: 40,
  color: theme.palette.text.light.secondary,
}))

export const SoldOutDescription = styled('div')(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.text.light.secondary,
  textAlign: 'center',
  width: 304,
}))
