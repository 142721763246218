import type {
  CurrentRefinementsConnectorParamsItem,
  CurrentRefinementsConnectorParamsRefinement,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { InstantSearchServerState } from 'react-instantsearch-hooks-web'

import { SiteInfo } from '@redux/rootReducer'
import { IAlgoliaHit } from '@typesApp/product'
import { ISeo } from '@typesApp/seo'
import { flatRefinementList } from './Utils'
import { TmpCookiesObj } from 'cookies-next/lib/types'
import { IGiftListItem } from '@typesApp/wishlist'
import { ParsedUrlQuery } from 'querystring'

export type Refinement = CurrentRefinementsConnectorParamsRefinement & {
  refine: (refinement: CurrentRefinementsConnectorParamsRefinement) => void
}

export type SortOrderOption = {
  id: number
  label: string
  value: string
  profileName: string
}

export type IPlpStateBase = {
  pageType: string | undefined
  seoData: ISeo | null
  storeInfo: SiteInfo | null
  serverUrl: string
  cookies: TmpCookiesObj
  searchParams: ParsedUrlQuery
  hostname: string
}

export type IPlpState = IPlpStateBase & {
  _internalAppliedFacets: Refinement[]
  appliedFacets: Refinement[]
  locale: string
  categoryId: string
  categoryIdentifier: string
  groupedIndexName: string
  ungroupedIndexName: string
  algoliafilterCategory: string
  isAlgoliaBasedPlp: boolean
  openDrawerFilters: boolean
  sortOrderOptions: SortOrderOption[]
  showOffersFilters: boolean
  serverState?: InstantSearchServerState
  shouldLoadUngrouped?: boolean
  firstLoad: boolean
  productsMobileListingLayout: 'compact' | 'full'
  lastSelectedProduct: string
  ungroupedHits: IAlgoliaHit[]
  isPlpSeoEnabled: { top: boolean; bottom: boolean }
  analyticsData: IPlpAnalyticsData
  isWishlistEnabled: boolean
  wishlistItems: IGiftListItem[]
  redirectUrl?: string | null
}

const TOGGLE_DRAWER_FILTERS = 'TOGGLE_DRAWER_FILTERS'
const SET_MULTIPLE = 'SET_MULTIPLE'
const SET_SHOULD_LOAD_UNGROUPED = 'SET_SHOULD_LOAD_UNGROUPED'
const SET_FIRST_LOAD = 'SET_FIRST_LOAD'
const SET_INTERNAL_APPLIED_FILTERS = 'SET_INTERNAL_APPLIED_FILTERS'
const SET_PRODUCTS_MOBILE_LISTING_LAYOUT = 'SET_PRODUCTS_MOBILE_LISTING_LAYOUT'
const SET_LAST_SELECTED_PRODUCT = 'SET_LAST_SELECTED_PRODUCT'
const SET_WISHLIST_ITEMS = 'SET_WISHLIST_ITEMS'

type IActionToggleDrawerFilters = {
  type: typeof TOGGLE_DRAWER_FILTERS
  payload: IPlpState['openDrawerFilters']
}

type IActionSetMultiple = {
  type: typeof SET_MULTIPLE
  payload: Partial<IPlpState>
}

type IActionSetShouldLoadUngrouped = {
  type: typeof SET_SHOULD_LOAD_UNGROUPED
  payload: IPlpState['shouldLoadUngrouped']
}

type IActionSetFirstLoad = {
  type: typeof SET_FIRST_LOAD
  payload: IPlpState['firstLoad']
}

type IActionSetInternalAppliedFilters = {
  type: typeof SET_INTERNAL_APPLIED_FILTERS
  payload: Partial<{
    shouldLoadUngrouped: IPlpState['shouldLoadUngrouped']
    _internalAppliedFacets: CurrentRefinementsConnectorParamsItem[]
  }>
}
type IActionSetProductsMobileListingLayout = {
  type: typeof SET_PRODUCTS_MOBILE_LISTING_LAYOUT
  payload: IPlpState['productsMobileListingLayout']
}

type IActionSetLastSelectedProduct = {
  type: typeof SET_LAST_SELECTED_PRODUCT
  payload: IPlpState['lastSelectedProduct']
}

type IActionSetWishlistItems = {
  type: typeof SET_WISHLIST_ITEMS
  payload: IPlpState['wishlistItems']
}

export type IActionPlpType =
  | IActionToggleDrawerFilters
  | IActionSetMultiple
  | IActionSetShouldLoadUngrouped
  | IActionSetFirstLoad
  | IActionSetInternalAppliedFilters
  | IActionSetProductsMobileListingLayout
  | IActionSetLastSelectedProduct
  | IActionSetWishlistItems

export default function plpReducer(state: IPlpState, action: IActionPlpType): IPlpState {
  switch (action.type) {
    case TOGGLE_DRAWER_FILTERS:
      return {
        ...state,
        openDrawerFilters: action.payload,
      }
    case SET_SHOULD_LOAD_UNGROUPED:
      return {
        ...state,
        shouldLoadUngrouped: action.payload,
      }
    case SET_FIRST_LOAD:
      return {
        ...state,
        firstLoad: action.payload,
      }
    case SET_INTERNAL_APPLIED_FILTERS:
      const newState = { ...state }
      const flattenRefinements = flatRefinementList(action.payload._internalAppliedFacets || [])
      if (action.payload.shouldLoadUngrouped) {
        newState._internalAppliedFacets = flattenRefinements
        newState.appliedFacets = flattenRefinements
      }
      newState.shouldLoadUngrouped = action.payload.shouldLoadUngrouped
      return newState
    case SET_PRODUCTS_MOBILE_LISTING_LAYOUT:
      return {
        ...state,
        productsMobileListingLayout: action.payload,
      }
    case SET_LAST_SELECTED_PRODUCT:
      return {
        ...state,
        lastSelectedProduct: action.payload,
      }
    case SET_WISHLIST_ITEMS:
      return {
        ...state,
        wishlistItems: action.payload,
      }
    case SET_MULTIPLE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

type IPlpAnalyticsData = {
  Page_Brand: string
  Page_Design: string
  Page_Country: string
  Page_Language: string
  Page_DeviceType: string
  Page_Platform: string
  Page_Server: string
  Page_Section2: string
  Order_Currency: string
  Store_Id: string
}
