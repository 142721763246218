import styled from '@mui/material/styles/styled'

export const ProductNameWrapper = styled('div', {
  name: 'Product',
  slot: 'NameWrapper'
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}))

export const ProductBrandWrapper = styled('div', {
  name: 'Product',
  slot: 'BrandWrapper'
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

export const ProductName = styled('span', {
  name: 'Product',
  slot: 'Name'
})(() => ({
  flexGrow: 0,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  textTransform: 'uppercase',
}))

export const ProductBrand = styled('span', {
  name: 'Product',
  slot: 'Brand'
})(() => ({
  flexGrow: 0,
  fontSize: 12,
  fontWeight: 400,
  lineHeight: 1.33,
  textTransform: 'uppercase',
}))
