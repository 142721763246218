import Log from '@services/Log'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { useEffect, useState } from 'react'

import { IPlpProductResponse } from '@features/plp/query'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { IDynamicContentKey, IDynamicRule, LXDynamicContent } from '@typesApp/cmsPlacement/LXDynamicContent'
import { IPlacement, IPlacementItem, isCMCollection, isDynamicContent } from '@typesApp/cmsPlacement/Placement'
import { IProduct } from '@typesApp/product'
import { usePageType } from './usePageType'

type DynamicContentKeyMapping = Record<IDynamicContentKey, string[]> | {}
// type Product = Pick<IProduct, 'attributes' | 'cluster'>

/**
 * Create product attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
// const mapItemAttributes = (pdpData: IProduct): DynamicContentKeyMapping => {
//   const productAttributesMap: DynamicContentKeyMapping = {}
//   try {
//     dynamicContentKeys.map(key => {
//       if (!productAttributesMap[key]) {
//         productAttributesMap[key] = []
//       }
//       let mapElement = productAttributesMap[key]
//       const attrValue = getProductAttribute(pdpData, key)
//       if (attrValue && attrValue !== '') {
//         if (isArray(attrValue)) {
//           attrValue.map(v => {
//             mapElement && mapElement.push(`${key}_${v}`)
//           })
//         } else {
//           mapElement && mapElement.push(`${key}_${attrValue}`)
//         }
//       }
//       mapElement = union(mapElement)
//     })
//   } catch (e: any) {
//     Log.error('DYNAMIC PDP CONTENT MAPPING ERROR', e)
//   }
//   Log.info('DYNAMIC PDP CONTENT MAPPING', JSON.stringify(productAttributesMap))
//   return productAttributesMap
// }

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
// const mapCartItemsAttributes = (orderItems: Product[]): DynamicContentKeyMapping => {
//   const orderItemAttributes: IProductAttribute[] = []
//   const orderItemAttributesMap: DynamicContentKeyMapping = {}
//   try {
//     orderItems.map(item => {
//       concat(orderItemAttributes, item.attributes)
//         .filter(attr => {
//           return attr.identifier && dynamicContentKeys.includes(attr.identifier as IDynamicContentKey)
//         })
//         .map(attr => {
//           if (attr?.name && !orderItemAttributesMap[attr?.name]) {
//             orderItemAttributesMap[attr?.name] = []
//           }
//           const attrValue = attr?.name ? getProductAttribute(item, attr?.name) : null
//           if (attr?.name) {
//             if (isArray(attrValue)) {
//               attrValue.map(v => {
//                 attr?.name &&
//                   orderItemAttributesMap[attr?.name] &&
//                   orderItemAttributesMap[attr?.name].push(`${attr?.name}_${v}`)
//               })
//             } else {
//               orderItemAttributesMap[attr?.name] &&
//                 orderItemAttributesMap[attr?.name].push(`${attr?.name}_${attrValue}`)
//             }
//             orderItemAttributesMap[attr?.name] = union(orderItemAttributesMap[attr?.name])
//           }
//         })
//     })
//   } catch (e: any) {
//     Log.error('DYNAMIC CONTENT MAPPING ERROR', e)
//   }
//   Log.info('DYNAMIC CONTENT MAPPING', JSON.stringify(orderItemAttributesMap))
//   return orderItemAttributesMap
// }

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
// const mapAttributesFromFacets = (selectedFacets, facets): DynamicContentKeyMapping => {
//   const facetsMap: DynamicContentKeyMapping = {}
//   try {
//     forEach(selectedFacets, (_, selectedFacetKey) => {
//       forEach(facets, facet => {
//         facet.entry?.map(entry => {
//           if (entry.value === selectedFacetKey) {
//             const facetName = facet.name
//             if (!facetsMap[facetName]) {
//               facetsMap[facetName] = []
//               facetsMap[facetName].push(entry.attributeValueIdentifier)
//             } else if (facetName && facetsMap[facetName]) {
//               facetsMap[facetName].push(entry.attributeValueIdentifier)
//             }
//           }
//         })
//       })
//     })
//   } catch (e: any) {
//     Log.error('DYNAMIC CONTENT FACETS MAPPING', e)
//   }
//   Log.info('DYNAMIC CONTENT FACETS MAPPING', JSON.stringify(facetsMap))
//   return facetsMap
// }

/**
 * get dynamic cms content
 * @param dynamicContentKeyMapping dynamic content attributes map
 * @param dynamicRules dynamic rules from cms to be matched with attributes
 * @param dynamicItem dynamic cms entity
 */
const getDynamicContentByRules = (
  dynamicContentKeyMapping: DynamicContentKeyMapping,
  dynamicRules: IDynamicRule[],
  dynamicItem: LXDynamicContent
): ILXTeaser | ICMCollection | null => {
  let matchedRule: IDynamicRule | null = null
  try {
    matchedRule = dynamicRules?.filter(dynamicRule => {
      const match = dynamicRule?.rules.find(rule => {
        const attributesValues: string[] = dynamicContentKeyMapping[rule?.key]
        return !!rule.value && attributesValues?.includes(rule?.value)
      })
      return !!match
    })[0]
    Log.info('DYNAMIC CONTENT RULE MATCH: ' + JSON.stringify(matchedRule))
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT MATCH ERROR: ' + e)
  }
  if (matchedRule !== null && !!matchedRule?.target) {
    return matchedRule.target
  } else {
    Log.info('DYNAMIC CONTENT DEFAULT MATCH: ' + JSON.stringify(matchedRule))
    return dynamicItem.dynamicDefault
  }
}

const processPlacementItem = (item: IPlacementItem, dynamicContentMap: DynamicContentKeyMapping): IPlacementItem => {
  if (item && isDynamicContent(item)) {
    const dynamicContentByRules = getDynamicContentByRules(dynamicContentMap, item.dynamicRules || [], item)

    return dynamicContentByRules!
  } else {
    return item
  }
}

export const getUpdatedCmsContent = (
  cmsBannerData: IPlacement[],
  dynamicContentMap: DynamicContentKeyMapping
): IPlacement[] => {
  try {
    return (cmsBannerData || [])?.map(placement => ({
      ...placement,
      items: placement.items.map(placementItem => {
        if (isCMCollection(placementItem)) {
          return {
            ...placementItem,
            teasableItems: placementItem.teasableItems.map(item => processPlacementItem(item, dynamicContentMap)),
          }
        }

        const formattedPlacementItem = processPlacementItem(placementItem, dynamicContentMap)

        return formattedPlacementItem
      }),
    }))
  } catch (e: any) {
    Log.error('DYNAMIC CONTENT FETCH ERROR: ' + e)

    return []
  }
}

export const useDynamicCmsContentPlpAlgolia = (cmsBannerData: IPlacement[] | undefined, selectedFacets: any[]) => {
  const [dynamicContentBanners, setDynamiContentBanners] = useState<IPlacement[] | undefined>([])

  useEffect(() => {
    if (cmsBannerData && cmsBannerData.length > 0) {
      const plpDynamicContentMap = selectedFacets.reduce((acc, curr) => {
        const { attribute, value } = curr
        if (!/(sort\.price|sort\.discount)/.test(attribute)) {
          const attributeName = attribute.split('.')[1]
          const parsedValue = value?.toString()?.includes('|') ? value?.split('|')[1] : value
          const attributeValueIdentifier = `${attributeName}_${parsedValue}`
          if (acc[attributeName]) {
            acc[attributeName] = [...acc[attributeName], attributeValueIdentifier]
          } else {
            acc[attributeName] = [attributeValueIdentifier]
          }
        }
        return acc
      }, {})
      const plpBannersData =
        cmsBannerData && plpDynamicContentMap && getUpdatedCmsContent(cmsBannerData, plpDynamicContentMap)
      setDynamiContentBanners(plpBannersData)
    }
  }, [cmsBannerData, selectedFacets])

  return dynamicContentBanners
}

export const useDynamicCmsContent = (
  cmsBannerData: IPlacement[] | undefined,
  pdpData?: IProduct | null,
  facets?: IPlpProductResponse['facets'] | undefined,
  selectedFacets?: IPlpProductResponse['selectedFacets'] | undefined
) => {
  const pageType = usePageType()
  // const orderItems: Product[] = useSelector(orderItemsSelector)

  const [dynamicContentBanners, setDynamiContentBanners] = useState<IPlacement[] | undefined>([])

  useEffect(() => {
    if (cmsBannerData && cmsBannerData.length > 0) {
      switch (pageType.pageType) {
        case 'pdp':
          const pdpDynamicContentMap = pdpData && pdpData !== null ? pdpData.productAttributes : {}

          const pdpBannersData =
            cmsBannerData && pdpDynamicContentMap && getUpdatedCmsContent(cmsBannerData, pdpDynamicContentMap)

          setDynamiContentBanners(pdpBannersData)

          break
        // case 'plp':
        //   const plpDynamicContentMap = mapAttributesFromFacets(selectedFacets, facets) || {}
        //   const plpBannersData =
        //     cmsBannerData && plpDynamicContentMap && getUpdatedCmsContent(cmsBannerData, plpDynamicContentMap)
        //   setDynamiContentBanners(plpBannersData)
        //   break
        // case 'cart':
        //   const cartDynamicContentMap = mapCartItemsAttributes(orderItems) || {}
        //   const cartBannersData =
        //     cmsBannerData && cartDynamicContentMap && getUpdatedCmsContent(cmsBannerData, cartDynamicContentMap)
        //   setDynamiContentBanners(cartBannersData)
        //   break
      }
    }
  }, [/*orderItems, selectedFacets, facets,*/ pdpData, cmsBannerData])

  return { dynamicContentBanners }
}
