import React, { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { PdpContainer, PdpLeftColumn, PdpRightColumn } from '../../ProductDetails.style'
import {
  ProductDetailsSkeletonContainer,
  ProductDetailsThumbnailsSkeletonContainer,
  ProductDetailsSliderSkeletonContainer,
  ProductDetailsAttributesSkeletonContainer,
  ProductDetailsBreadCrumbsSkeletonContainer,
} from './ProductDetailsSkeleton.style'

const ProductDetailsSkeleton: FC = ({}) => (
  <>
    {
      <>
        <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
          <ProductDetailsBreadCrumbsSkeletonContainer>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: 0,
              }}
            />
            <Skeleton
              style={{
                height: '16px',
                borderRadius: 0,
              }}
            />
            <Skeleton
              style={{
                height: '16px',
                borderRadius: 0,
              }}
            />
            <Skeleton
              style={{
                height: '16px',
                borderRadius: 0,
              }}
            />
          </ProductDetailsBreadCrumbsSkeletonContainer>
        </SkeletonTheme>
        <PdpContainer>
          <PdpLeftColumn xs={'fullWidth'} md={8} xl={9}>
            <ProductDetailsSkeletonContainer>
              <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
                <ProductDetailsThumbnailsSkeletonContainer>
                  <Skeleton
                    style={{
                      height: '100%',
                      borderRadius: 0,
                    }}
                  />
                </ProductDetailsThumbnailsSkeletonContainer>
                <ProductDetailsSliderSkeletonContainer>
                  <Skeleton
                    style={{
                      borderRadius: 0,
                      height: '100%',
                    }}
                  />
                </ProductDetailsSliderSkeletonContainer>
              </SkeletonTheme>
            </ProductDetailsSkeletonContainer>
          </PdpLeftColumn>
          <PdpRightColumn xs={'fullWidth'} md={4} xl={3}>
            <ProductDetailsSkeletonContainer>
              <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
                <ProductDetailsAttributesSkeletonContainer>
                  <Skeleton style={{ borderRadius: 0, height: '75px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '150px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '75px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '50px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                  <Skeleton style={{ borderRadius: 0, height: '20px' }} />
                </ProductDetailsAttributesSkeletonContainer>
              </SkeletonTheme>
            </ProductDetailsSkeletonContainer>
          </PdpRightColumn>
        </PdpContainer>
      </>
    }
  </>
)

export default ProductDetailsSkeleton
