import styled from '@mui/material/styles/styled'

export const StyledSizeAdvisorDescr = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledSizeAdvisorDescr',
})(() => ({
  display: 'flex',
  width: '100%',
}))

export const StyledFrameGeniusPhoto = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledFrameGeniusPhoto',
})<{ url?: string }>(({ url, theme }) => ({
  borderRadius: '50%',
  width: '55px',
  height: '55px',
  background: url
    ? `url(${url}) center center / cover rgb(255, 255, 255)`
    : theme.palette.background.dark.primary,
  marginRight: '15px',
  flex: '0 0 55px',
}))

export const StyledSizeAdvisorSwitcherContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledSizeAdvisorSwitcherContainer',
})(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}))
