import React from 'react'
import { IProduct } from '../../../types/product'
import { sortBy } from 'lodash-es'
import { StyledTooltip } from '../../../components/UI'
import { useTranslation } from 'next-i18next'
import { StyledProductSpecialAttributeSpan, StyledSpecialFrameInfoWrapper } from './SpecialFrameInfo.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProductDetailsList {
  currentProduct?: IProduct
}

const attrName = (attributeKey: string, attributeValue: string): string => {
  if (attributeKey === 'GEOFIT') {
    return attributeValue.replaceAll(' ', '').toUpperCase()
  }
  return attributeValue
}

const ProductSpecialAttribute: React.FC<{
  attributeKey: string
  attributeValue: string
}> = ({ attributeKey, attributeValue }) => {
  const { t } = useTranslation()
  return (
    <StyledProductSpecialAttributeSpan>
      <span>{t(`ProductDetails.SpecialFrameInfo.Label.${attrName(attributeKey, attributeValue)}`)}</span>
      <StyledTooltip
        placement="top"
        contentVariant={{
          type: 'textonly',
          body: t(`ProductDetails.SpecialFrameInfo.Tooltip.${attrName(attributeKey, attributeValue)}`),
        }}
        colorVariant={'darkgray'}
        enterTouchDelay={0}
        clickable
      >
        <div>
          <SVGIcon library="validation" name="info" />
        </div>
      </StyledTooltip>
    </StyledProductSpecialAttributeSpan>
  )
}

export const SpecialFrameInfo = ({ currentProduct }: IProductDetailsList) => {
  const { t, i18n } = useTranslation()
  const attributesKeyToDisplay = ['GEOFIT', 'POLARIZED', 'PHOTOCHROMIC']

  const filterAttribute = (attributeKey: string, attributeValue: string): boolean => {
    return (
      attributesKeyToDisplay.includes(attributeKey) &&
      (attributeKey === 'GEOFIT'
        ? attributeValue === 'Universal Fit' || 'Low Bridge Fit' || 'High Bridge Fit' || 'Adjustable Nosepads'
        : attributeValue === 'True') &&
      i18n.exists(`ProductDetails.SpecialFrameInfo.Label.${attrName(attributeKey, attributeValue)}`)
    )
  }

  const attributeKeys =
    currentProduct &&
    currentProduct.productAttributes &&
    sortBy(Object.keys(currentProduct.productAttributes)).filter(key =>
      filterAttribute(key, currentProduct.productAttributes[key])
    )

  return (
    <StyledSpecialFrameInfoWrapper>
      {attributeKeys && attributeKeys?.length > 0 && (
        <>
          {t('ProductDetails.SpecialFrameInfo.Label.modelFeatures')}
          {attributeKeys.map((attrKey, index) => {
            return index >= 1 ? (
              <React.Fragment key={`product-attribute-wrapper-${attrKey}-${index}`}>
                {' '}
                and{' '}
                <ProductSpecialAttribute
                  key={`product-attribute-${attrKey}-${index}`}
                  attributeKey={attrKey}
                  attributeValue={currentProduct.productAttributes[attrKey]}
                />
              </React.Fragment>
            ) : (
              <React.Fragment key={`product-attribute-wrapper-${attrKey}-${index}`}>
                {' '}
                <ProductSpecialAttribute
                  key={`product-attribute-special-${attrKey}-${index}`}
                  attributeKey={attrKey}
                  attributeValue={currentProduct.productAttributes[attrKey]}
                />
              </React.Fragment>
            )
          })}
        </>
      )}
    </StyledSpecialFrameInfoWrapper>
  )
}
