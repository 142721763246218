import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import { IProduct, ClusterProduct } from '@typesApp/product'
import { getModelName } from '@utils/productAttributesAlgolia'
import { useCallback, useState } from 'react'
import { StyledContainer, StyledSwitcherContainer, VirtualMirrorPill } from '../../ProductDetails.style'
import VirtualMirror from '../VirtualMirror/VirtualMirror'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

type VirtualMirrorCTAProps = {
  labelText: string
  currentProduct: IProduct
  pdpData: IProduct
}

const VirtualMirrorCTA: React.FC<VirtualMirrorCTAProps> = ({ labelText, currentProduct, pdpData }) => {
  const [isVMMVOpen, setIsVMMVOpen] = useState(false)

  const handleVMMVClickOpen = useCallback(() => {
    setIsVMMVOpen(true)
  }, [])

  const handleVMMVClickClose = useCallback(() => {
    setIsVMMVOpen(false)
  }, [])

  return (
    <StyledContainer>
      <StyledSwitcherContainer>
        <VirtualMirrorPill
          labelText={labelText}
          startIcon={<SVGIcon library="global" name="camera" size={20} />}
          onClick={handleVMMVClickOpen}
          variant={'secondary'}
          id="vmSwitch"
          data-element-id="X_X_Prod_VMStart"
          data-description={`${getModelName(pdpData)}_${pdpData.partNumber}`}
        />
      </StyledSwitcherContainer>
      {isVMMVOpen && (
        <ClientOnlyPortal selector="#vmPortal">
          <VirtualMirror
            currentProduct={currentProduct}
            onClose={handleVMMVClickClose}
            cluster={pdpData.cluster ? (pdpData.cluster as ClusterProduct[]) : undefined}
          />
        </ClientOnlyPortal>
      )}
    </StyledContainer>
  )
}

export default VirtualMirrorCTA
