import styled from '@mui/material/styles/styled'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { IProduct } from '../../types/product'
import { seoUrlConcat } from '../../utils/url'
import BreadCrumbs from '../UI/BreadCrumbs'

export interface BreadcrumbProps {
  cid: string
  className?: string
  searchTermText: string
  product?: IProduct
  breadcrumbsList?: any[]
  langCode: string
}

export const BreadcrumbsWrapper = styled('div', {
  name: 'Breadcrumbs',
  slot: 'Wrapper',
})(({ theme }) => ({
  marginLeft: `${theme.spacing(4)}`,
  marginTop: `${theme.spacing(3)}`,

  [theme.breakpoints.up('lg')]: {
    marginLeft: `${theme.spacing(16)}`,
  },
}))

const StyledBreadcrumbLinkA = styled('a', {
  name: 'Breadcrumbs',
  slot: 'Link',
})(() => ({
  textDecoration: 'none',
  color: 'inherit',
}))

/**
 * Breadcrumb component
 * displays breadcrumb trail
 * @param props
 */
const BreadcrumbLayout: React.FC<BreadcrumbProps> = ({
  cid,
  className,
  searchTermText,
  product,
  breadcrumbsList,
  langCode,
}) => {
  const { t } = useTranslation()
  //const breadcrumbsList = useSelector(breadcrumbsSelector)
  const breadcrumbs = cid.includes('search-results')
    ? [{ label: searchTermText.replace('*', '') }]
    : breadcrumbsList || []

  const _getLabel = (breadcrumb: (typeof breadcrumbs)[0]) => {
    if (product) {
      return product.productAttributes['MODEL_NAME']
    }
    return breadcrumb.label
  }

  const StyledBreadcrumbLink = StyledBreadcrumbLinkA

  return (
    <BreadcrumbsWrapper className={className || ''}>
      <BreadCrumbs isLoading={!(breadcrumbs && breadcrumbs.length)}>
        <StyledBreadcrumbLink
          color="inherit"
          data-element-id={'X_X_Top_Breadcrumb'}
          href={seoUrlConcat('', langCode)}
          id={`breadcrumb_a_1_${cid}`}
        >
          {t('CommerceEnvironment.Breadcrumbs.Home')}
        </StyledBreadcrumbLink>

        {breadcrumbs?.map((breadcrumb, index: number) =>
          breadcrumb.seo && breadcrumb.seo.href ? (
            <StyledBreadcrumbLink
              href={seoUrlConcat('', breadcrumb.seo.href)}
              key={breadcrumb.value}
              data-element-id={'X_X_Top_Breadcrumb'}
              id={`breadcrumb_a_1_${cid}_${index}`}
            >
              {breadcrumb.label}
            </StyledBreadcrumbLink>
          ) : (
            <span key={breadcrumb.value + index}>{_getLabel(breadcrumb)}</span>
          )
        )}
        {product && <span key={product.id}>{_getLabel(breadcrumbs[breadcrumbs?.length])}</span>}
      </BreadCrumbs>
    </BreadcrumbsWrapper>
  )
}

export default BreadcrumbLayout
