import React, { FC } from 'react'
import { TitleContainer } from '../PlpHeader.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const PlpHeaderTitle: FC<{
  text?: string
  isSticky?: boolean
  catalogLoading?: boolean
}> = ({ text = '', isSticky = false, catalogLoading }) => (
  <TitleContainer isSticky={isSticky}>
    {catalogLoading ? (
      <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
        <Skeleton containerClassName="dc_plp_header_skeleton" />
      </SkeletonTheme>
    ) : (
      text || (
        <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
          <Skeleton containerClassName="dc_plp_header_skeleton" />
        </SkeletonTheme>
      )
    )}
  </TitleContainer>
)

export default PlpHeaderTitle
