import { AppDispatch } from '@redux/store'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { preFooter, contentMegaMenu } from './slice'
import { IContentMegaMenu } from '@typesApp/cms'

export const setPreFooter = (preFooterData: IPlacement) => (dispatch: AppDispatch) => {
  dispatch(preFooter(preFooterData || null))
}

export const setContentMegaMenuData = (contentMegaMenuData: IContentMegaMenu[]) => (dispatch: AppDispatch) => {
  dispatch(contentMegaMenu(contentMegaMenuData))
}
