import React, { FC, useEffect, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import * as S from './PDPStickyBar.style'
import ProductCtaContainer from '../ProductCtaContainer'
import type { Attachment, ProductSoldOutStatus, IProduct } from '@typesApp/product'
import { Adjustment, OrderItem } from '@typesApp/order'

import { cartSelector, orderItemsSelector } from '../../../../features/order/selector'

import { isRxCart, parseOrderItems } from '../../../../utils/isRxOrder'
import theme from '../../../../themes'
import {
  determineAlgoliaPrice,
  getProductAlgoliaPrice,
  shouldShowAbsoluteDiscount,
} from '../../../../foundation/algolia/algoliaPrice'
import ProductPriceAlgolia from '../ProductPriceAlgolia'
import { getBrand, getCLBrand, getModelName, getNormalizedProductType } from '../../../../utils/productAttributes'
import { ModelName, BrandTitle } from '../PDPStickyBar.style'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { getCustomerSegmentsfromCookie } from '../../../../features/plp/algoliaUtils'
import { APP_MAIN_CONTAINER_ID } from '../../../../constants/ui'
import { toNumber } from 'lodash-es'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { CartInterceptListener } from '../../../../components/DirectBilling/components'
import { getProductImageAltLabel } from '@utils/productImage'
import { SubscriptionInfo } from '@typesApp/subscription'
import { useSite } from '@foundation/hooks/useSite/useSite'
import { CommerceEnvironment } from '@constants/common'
import ErrorMessage from '@components/UI-CSS/ErrorMessage'
import styles from './styles/PDPStickyBar.module.scss'
import productUtils from '@utils/ProductUtils'

export interface PDPStickyBarProps {
  loading: boolean
  partNumber: string
  product: IProduct
  images?: Attachment[]
  currentIndex: number
  subscriptionInfo?: SubscriptionInfo
  soldOutStatus: ProductSoldOutStatus
  addToCartButtonFail: boolean
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  isRoxable?: boolean
  visible?: boolean
  hasLens?: boolean
  styleguide?: boolean
  productQuantity?: string[]
  adjustments?: Adjustment[]
  onAddToCartClick?: () => void
}

const PDPStickyBar: FC<PDPStickyBarProps> = props => {
  const {
    loading,
    partNumber,
    product,
    images,
    currentIndex,
    soldOutStatus,
    addToCartButtonFail,
    stickyBarCtaRef,
    isRoxable,
    visible,
    productQuantity,
    subscriptionInfo,
    adjustments,
    onAddToCartClick,
  } = props
  const { mySite } = useSite()
  const { t: translate } = useTranslation()

  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const areImagesAvailable = images && images.length > 0
  const { isMobile } = useBreakpoints()
  const customerSegment = getCustomerSegmentsfromCookie()

  const [clicked, setClicked] = React.useState<boolean>(false)
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(!!visible)

  let productType = getNormalizedProductType(product)
  //We want the SUN products type to be considered as frames so the sticky bar shows the  price that matches the Frame right column
  if (productType === PRODUCT_TYPES_KEYS.SUN) {
    productType = PRODUCT_TYPES_KEYS.FRAMES
  }

  const modelName = getModelName(product)
  const isCL = productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES
  const isCLAccessories =
    productType === PRODUCT_TYPES_KEYS.ACCESSORIES || productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

  const brandName = isCL || isCLAccessories ? getCLBrand(product) : getBrand(product)

  const handleScrollToTop = () => {
    setClicked(true)
    document.getElementById(APP_MAIN_CONTAINER_ID)?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const customerSegments = getCustomerSegmentsfromCookie()
  const totalBoxes = toNumber(productQuantity ? productQuantity[0] : isCL ? 2 : 1)
  const productInCart: OrderItem | undefined =
    (isRxCart(cart?.orderExtendAttribute) ? parseOrderItems(orderItems) : orderItems).find(
      i => i.partNumber === product?.partNumber || i.partNumber === partNumber
    ) || undefined

  const displayable = product.displayable
  const isDiscontinued = productUtils.isProductDiscontinued(product)

  const algoliaPrices = getProductAlgoliaPrice(customerSegment, product)
  const isDirectBillingEnabled =
    !!CommerceEnvironment.directInsuranceBilling[mySite.locale?.toLowerCase()]?.enabled ?? false

  const hasError = soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
  /**
   * This ensures that the image is only loaded once
   * when the sticky bar becomes visible.
   */
  useEffect(() => {
    if (visible && !isImageLoaded) {
      setIsImageLoaded(true)
    }
  }, [visible, isImageLoaded])

  return (
    <div
      className={clsx(styles.container, {
        [styles.visible]: !!visible,
      })}
      data-element-id="X_X_Stickybar"
    >
      {!isMobile && (
        <S.StyledInfoProductContainer>
          <BrandTitle>{brandName}</BrandTitle>
          <ModelName>{modelName}</ModelName>
        </S.StyledInfoProductContainer>
      )}
      <div className={styles.imageAndPriceContainer}>
        {isImageLoaded && areImagesAvailable && !isMobile && (
          <S.StyledProductImage
            alt={getProductImageAltLabel(product)}
            attachments={[images[currentIndex]]}
            style={{ cursor: 'pointer' }}
            usage="PDP"
            width={354}
            backgroundColor={theme.palette.background.light.primary}
          />
        )}

        <div
          className={clsx(styles.priceContainer, {
            [styles.priceContainerWithError]: hasError,
          })}
        >
          {displayable && (
            <div className={styles.priceInnerContainer}>
              {product && product.x_price && (
                <ProductPriceAlgolia
                  adjustments={adjustments}
                  isLoading={false}
                  productType={productType}
                  price={determineAlgoliaPrice(product?.x_price, customerSegments, productType)}
                  isCompact={true}
                  type={soldOutStatus}
                  isPDP={true}
                  isCL={isCL}
                  isCLAccessory={isCLAccessories}
                  totalBoxes={totalBoxes}
                  isRoxable={isRoxable}
                  isAbsoluteDiscount={shouldShowAbsoluteDiscount(product)}
                  isStickyBar
                  renderFramePriceLabel={isMobile}
                />
              )}
            </div>
          )}
          {hasError && !isDiscontinued && (
            <ErrorMessage className={styles.errorContainer} errorTitle={translate('OutOfStock.SorrySoldOut')} />
          )}
          {hasError && isDiscontinued && (
            <ErrorMessage className={styles.errorContainer} errorTitle={translate('OutOfStock.SorryDiscontinued')} />
          )}
        </div>

        {product && displayable && (
          <>
            <ProductCtaContainer
              productInCart={productInCart}
              isLoading={loading}
              subscriptionInfo={subscriptionInfo}
              soldOutStatus={soldOutStatus}
              currentProduct={product}
              error={addToCartButtonFail}
              ctaRef={stickyBarCtaRef}
              addToCartFillType={'fill'}
              roxable={isRoxable}
              isStickyBar
              productQuantity={productQuantity}
              algoliaPrices={algoliaPrices}
              onAddToCartClick={onAddToCartClick}
            />
            {isDirectBillingEnabled && <CartInterceptListener />}
          </>
        )}
      </div>
      {!isMobile && (
        <S.StyledRoundPill
          aria-label="ScrollToTop"
          data-element-id="X_X_Stickybar_ScrollUp"
          startIcon={<KeyboardArrowUpIcon />}
          variant="secondary"
          onClick={handleScrollToTop}
          clicked={clicked}
        />
      )}
    </div>
  )
}

export default PDPStickyBar
