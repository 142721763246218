import React from 'react'
import { StyledAccordion, StyledAccordionSummary, StyledFiltersContainer } from '../PlpFilter.style'
import { useTranslation } from 'next-i18next'
import FacetFilterPill from './FacetFilterPill'

export interface ISuggestedFacetFilterWrapper {
  suggestedFacets: { key: string; value: string[] }[]
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
}

const SuggestedFacetFilterWrapper: React.FC<ISuggestedFacetFilterWrapper> = ({ suggestedFacets, onFacetChange }) => {
  const { t } = useTranslation()
  return (
    <StyledAccordion defaultExpanded={true}>
      <StyledAccordionSummary>{t('ProductFilter.Labels.Suggested')}</StyledAccordionSummary>
      <div>
        <StyledFiltersContainer>
          {suggestedFacets.map(({ key, value }) => {
            return (
              value &&
              value.length > 0 && (
                <FacetFilterPill
                  facetValues={value}
                  facetName={key.split('.')[1]}
                  facet={key}
                  onFacetChange={onFacetChange}
                />
              )
            )
          })}
        </StyledFiltersContainer>
      </div>
    </StyledAccordion>
  )
}

export default SuggestedFacetFilterWrapper
