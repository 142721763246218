import styled from '@mui/material/styles/styled'
import ProductImage from '../../../components/ProductImage/ProductImage'

export const ProductColorSelectorItem = styled('span', {
  name: 'ColorSelector',
  slot: 'item',
  shouldForwardProp: prop => prop !== 'isSoldOut',
})<{ isSoldOut: boolean }>(({ isSoldOut, theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.light[isSoldOut ? 'secondary' : 'primary'],
}))

export const ProductColorSelectorItemImage = styled(ProductImage)(() => ({
  transform: 'translateX(-9%) translateY(5%)',
  width: '120%',
  height: '100%',
}))
