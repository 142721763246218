import { Attachment, ServerProductAttribute, ProductSoldOutStatus, IProduct } from '@typesApp/product'
import {
  AttributeLabel,
  AttributesContent,
  CheckboxContainer,
  CloseSubscribeButton,
  Container,
  Content,
  Description,
  DescriptionFailure,
  DescriptionSuccess,
  Header,
  PrivacyLink,
  PrivacyPolicyContainer,
  StyledOOSFormControlLabel,
  SubscribeButton,
  Title,
  StyledProductImage,
} from './ProductAvailableNotificationDrawer.style'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import {
  getFrameShape,
  getLensesColor,
  getLensesTreatment,
  getModelCode,
  getSize,
} from '../../../../utils/productAttributes'

import { IconButton, StyledCheckbox, TextField } from '../../../../components/UI'
import Log from '../../../../services/Log'
import ProductAvailableNotification from '../../../../foundation/apis/product/product.service'
import { REG_EX } from '../../../../constants/common'
import { sendNewsletterSubscriptionEvent } from '../../../../foundation/analytics/tealium/lib'
import { useSite } from '../../../../foundation/hooks/useSite'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenDrawerProductNotificationAvailable } from '../../../../features/ui/action'
import { openDrawerProductNotificationSelector } from '../../../../features/ui/selector'
import { SwipeableDrawer, useTheme } from '@mui/material'
import config from '@configs/config.base'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { useSubscribeNewsletterMutation } from '../../../../features/newsletter/query'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  product: IProduct
  soldOutStatus: ProductSoldOutStatus
  attachments: Attachment[]
  attributes: ServerProductAttribute[]
}

const ProductAvailableNotificationDrawer: React.FC<IProps> = props => {
  const { product, soldOutStatus, attachments, attributes } = props
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<boolean>(false)
  const [subscriptionResult, setSubscriptionResult] = useState<boolean>()
  const [isNewsletterChecked, setNewsletterChecked] = useState<boolean>(false)
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(true)
  const productAvailableNotificationOpen = useSelector(openDrawerProductNotificationSelector)
  const [subscribeNewsletter] = useSubscribeNewsletterMutation()
  const [isPristine, setIsPristine] = React.useState<boolean>(true)

  const frameShape = product && getFrameShape(product)
  const lensesColor = getLensesColor(product)
  const lensesTreatment = product && getLensesTreatment(product)
  const productSize = product && getSize(product, t)

  useEffect(() => {
    setIsPristine(email.length <= 0)
  }, [email])

  const handleNewsletterChecked = () => setNewsletterChecked(v => !v)

  const handleNewsletterSubmit = async () => {
    subscribeNewsletter({
      email,
      newsletter_id: mySite.newsletterType,
      from: 'product-notification',
    })
      .unwrap()
      .then(() => {
        sendNewsletterSubscriptionEvent(email.toLowerCase())
      })
      .catch((e: any) => {
        Log.error(e as any as string)
      })
  }

  const handleEmailSubmit = async () => {
    try {
      const { transactionContext } = config
      const { storeID } = mySite
      await ProductAvailableNotification.subscribeToAvailableNotification({
        transactionContext,
        storeID,
        emailAddress: email,
        catentryId: `${product?.uniqueID}`,
      })
      setSubscriptionResult(true)
      setIsFooterVisible(false)
    } catch (e) {
      setSubscriptionResult(false)
      Log.error(e as any as string)
    }
  }

  const handleSubmit = async () => {
    if (REG_EX.EMAIL.test(email)) {
      if (isNewsletterChecked) {
        handleNewsletterSubmit()
      }
      await handleEmailSubmit()
    } else setEmailError(true)
  }

  const productImageWidth = useMemo<number>(() => {
    switch (true) {
      case isMobile:
        return 345
      case isTablet:
        return 740
      case isDesktop:
        return 378
      default:
        return 378
    }
  }, [isMobile, isTablet, isDesktop])

  const toggleproductNotificationAvailableDrawer = () =>
    dispatch(setOpenDrawerProductNotificationAvailable(!productAvailableNotificationOpen))

  return (
    <SwipeableDrawer
      disableSwipeToOpen={true}
      open={productAvailableNotificationOpen}
      onClose={toggleproductNotificationAvailableDrawer}
      onOpen={() => {}}
      anchor={'right'}
    >
      <Container>
        <Header>
          <Title>
            {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_SOON && t('ComingSoon.BeTheFirst')}
            {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON && t('ComingBackSoon.BeTheFirst')}
          </Title>
          <IconButton onClick={toggleproductNotificationAvailableDrawer}>
            <SVGIcon library="close" name="close" color={theme.palette.text.light.primary} />
          </IconButton>
        </Header>
        <Content>
          {subscriptionResult ? (
            <DescriptionSuccess>
              {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_SOON && t('ComingSoon.DescriptionSuccess')}
              {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON && t('ComingBackSoon.DescriptionSuccess')}
            </DescriptionSuccess>
          ) : (
            <Description>
              {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_SOON && t('ComingSoon.Description')}
              {soldOutStatus == PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON && t('ComingBackSoon.Description')}
            </Description>
          )}

          <StyledProductImage
            attachments={attachments}
            style={{ border: 'none' }}
            usage="Thumbnail"
            width={productImageWidth}
          />

          {product && attributes && (
            <AttributesContent>
              <div>
                <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.ModelName')}:</AttributeLabel>{' '}
                {getModelCode(product)}
              </div>
              {frameShape && (
                <div>
                  <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Frame')}:</AttributeLabel> {frameShape}
                </div>
              )}
              {lensesColor && lensesTreatment && (
                <div>
                  <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Lenses')}: </AttributeLabel>
                  {`${lensesColor} / ${lensesTreatment}`}
                </div>
              )}
              {productSize && (
                <div>
                  <AttributeLabel>{t('ProductAvailableNotificationDrawer.Labels.Size')}:</AttributeLabel> {productSize}
                </div>
              )}
            </AttributesContent>
          )}
          {subscriptionResult ? (
            <CloseSubscribeButton fullwidth onClick={toggleproductNotificationAvailableDrawer}>
              {t('ProductAvailableNotificationDrawer.CTA.SuccessButton')}
            </CloseSubscribeButton>
          ) : (
            <>
              <TextField
                label={t('ProductAvailableNotificationDrawer.Labels.Email')}
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value)
                  setEmailError(!/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(e.target.value))
                }}
                error={emailError && !isPristine}
                isvalid={!emailError && !isPristine}
                ispristine={isPristine}
                showvalidationstatus={true}
                required
                helperText={
                  emailError && !isPristine ? t('ProductAvailableNotificationDrawer.Msgs.InvalidEmail') : null
                }
              />

              <CheckboxContainer>
                <StyledOOSFormControlLabel
                  control={<StyledCheckbox checked={isNewsletterChecked} onChange={handleNewsletterChecked} />}
                  label={t('ProductAvailableNotificationDrawer.CheckboxNewsletterText')}
                />
              </CheckboxContainer>
              {isFooterVisible && (
                <PrivacyPolicyContainer>
                  <Trans i18nKey="ProductAvailableNotificationDrawer.PrivacyText">
                    {{
                      policyUrl: t('ProductAvailableNotificationDrawer.PrivacyTextLink'),
                    }}
                    <PrivacyLink href={`/${langCode}/c/legal/legal-policies`} />
                  </Trans>
                </PrivacyPolicyContainer>
              )}

              <SubscribeButton disabled={!email || emailError} onClick={handleSubmit} fullwidth>
                {t('ProductAvailableNotificationDrawer.CTA.Submit')}
              </SubscribeButton>
            </>
          )}
          {subscriptionResult === false ? (
            <DescriptionFailure>{t('ProductAvailableNotificationDrawer.DescriptionFailure')}</DescriptionFailure>
          ) : null}
        </Content>
      </Container>
    </SwipeableDrawer>
  )
}

export default ProductAvailableNotificationDrawer
