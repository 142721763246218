import styled from '@mui/material/styles/styled'

export const StyledSpecialFrameInfoWrapper = styled('div', {
  name: 'SpecialFrameInfo',
  slot: 'Wrapper'
})(() => ({
  fontSize: 12,
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: 21,
}))

export const StyledProductSpecialAttributeSpan = styled('span', {
  name: 'SpecialFrameInfo',
  slot: 'Attribute'
})(({ theme }) => ({
  display: 'flex',
  margin: `0 ${theme.spacing()} 0 ${theme.spacing()}`,

  span: {
    fontWeight: 'bold',
    marginRight: theme.spacing(),
  },
}))
