import React, { FC, useEffect, useRef } from 'react'
import { map } from 'lodash-es'
// TYPES
import { Attachment, ProductType } from '../../../types/product'
import { Swiper as SwiperClass } from 'swiper/types'
import { SwiperSlide } from 'swiper/react'
import {
  SliderNavigationButton,
  SliderThumbNail,
  SliderThumbNailContainer,
  SliderThumbNailsWrapper,
  ThumbNailBrandContainer,
  ThumbnailButtonContainer,
  WrapperThumbnails,
} from './ProductImagesSlider/ProductImagesSlider.style'

import { ProductImageProps } from '../../../components/ProductImage/ProductImage'
import BrandIcon from '../../../components/BrandIcon'
import theme from '../../../themes'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

/**
 * @param { Attachment[] } images thumbnails images
 * @param { number } currentIndex thumbnails selected index
 * @param { ProductImageProps } thumbnailImageProps thumbnails images props
 */

export interface ThumbnailsProps {
  productType?: ProductType | null
  thumbnailImageProps: ProductImageProps
  images?: Attachment[]
  currentIndex: number
  brandName?: string
  onThumbnailClick?: (imageIndex: number) => void
  isGalleryMode?: boolean
}

const PRODUCT_THUMBNAILS_PER_VIEW = 5

const ProductImagesThumbnails: FC<ThumbnailsProps> = ({
  images,
  currentIndex,
  onThumbnailClick,
  thumbnailImageProps,
  brandName,
  isGalleryMode,
}: ThumbnailsProps) => {
  const isPositionStart = currentIndex === 0
  const isPositionEnd = images?.length === currentIndex + 1

  useEffect(() => {
    swiperRef.current?.slideToLoop(currentIndex)
  }, [currentIndex])

  const swiperRef = useRef<SwiperClass | null>(null)

  return (
    <WrapperThumbnails>
      {brandName && (
        <ThumbNailBrandContainer>
          <BrandIcon name={brandName} />
        </ThumbNailBrandContainer>
      )}
      <SliderThumbNailsWrapper
        slidesPerView={PRODUCT_THUMBNAILS_PER_VIEW}
        spaceBetween={16}
        direction={'vertical'}
        onSwiper={swiper => (swiperRef.current = swiper)}
        isGalleryMode={isGalleryMode}
      >
        {images &&
          images?.length > 0 &&
          map(images, (t, i) => {
            const slideIdx = Math.abs(currentIndex - i)
            const isSlideInView = Math.floor(slideIdx / PRODUCT_THUMBNAILS_PER_VIEW) <= 0

            return (
              <SwiperSlide key={i}>
                <SliderThumbNailContainer selected={currentIndex === i}>
                  <SliderThumbNail
                    active={isSlideInView}
                    attachments={[t]}
                    autoPlay={false}
                    backgroundColor={theme.palette.background.light.primary}
                    lazy={false}
                    loadOnActive={true}
                    onClick={() => onThumbnailClick && onThumbnailClick(i)}
                    preload={i === 0}
                    {...thumbnailImageProps}
                  />
                </SliderThumbNailContainer>
              </SwiperSlide>
            )
          })}
      </SliderThumbNailsWrapper>
      {images && images?.length > 5 && (
        <ThumbnailButtonContainer>
          <SliderNavigationButton
            aria-label="SliderNavigationButtonUp"
            positionStart={isPositionStart}
            onClick={() => currentIndex && currentIndex !== 0 && onThumbnailClick && onThumbnailClick(currentIndex - 1)}
          >
            <SVGIcon library="arrow" name="arrow-up" color={theme.palette.custom.boulder} />
          </SliderNavigationButton>
          <SliderNavigationButton
            aria-label="SliderNavigationButtonDown"
            positionEnd={isPositionEnd}
            onClick={() =>
              images?.length !== currentIndex + 1 && onThumbnailClick && onThumbnailClick(currentIndex + 1)
            }
          >
            <SVGIcon library="arrow" name="arrow-down" color={theme.palette.custom.boulder} />
          </SliderNavigationButton>
        </ThumbnailButtonContainer>
      )}
    </WrapperThumbnails>
  )
}

export default ProductImagesThumbnails
