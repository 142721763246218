import styled from '@mui/material/styles/styled'

export const ProductDetailsSkeletonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    minHeight: 800,
  },
}))

export const ProductDetailsThumbnailsSkeletonContainer = styled('div')(
  ({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: '78px',
    },
  })
)

export const ProductDetailsSliderSkeletonContainer = styled('div')(
  ({ theme }) => ({
    flex: '1 0 auto',
    height: '300px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  })
)

export const ProductDetailsAttributesSkeletonContainer = styled('div')(() => ({
  flex: '1 0 auto',
}))

export const ProductDetailsBreadCrumbsSkeletonContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
    '& > *': {
      flex: '1 0 auto',
      maxWidth: '100px',
    },
  })
)
