import {
  ProdcuctBrand,
  ProdcuctNameWrapper,
  ProductBrandWrapper,
  ProductCLBrand,
  ProductCLName,
  ProductName,
} from './ProductNameSection.style'
import { getBrand, getCLBrand, getModelName } from '../../../utils/productAttributes'
import { isClAccessoriesProduct, isContactLensesProduct } from '../../../utils/product'

import React from 'react'
import { IProduct } from '../../../types/product'
import { WishListButton } from '@components/features/WishlistButton'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { wishlistEnabledSelector } from './../../../features/wishList/selector'

export interface IProductDetailsList {
  currentProduct: IProduct
  isSoldout?: boolean
  displayable?: boolean
}

export const ProductNameSection = ({ currentProduct, isSoldout, displayable }: IProductDetailsList) => {
  const { t } = useTranslation()
  const wishListEnabled = useSelector(wishlistEnabledSelector)

  return (
    <ProdcuctNameWrapper>
      {currentProduct && !(isContactLensesProduct(currentProduct) || isClAccessoriesProduct(currentProduct)) ? (
        <>
          <ProductBrandWrapper>
            <ProdcuctBrand>{getBrand(currentProduct) || t('ProductDetails.Labels.BrandName')}</ProdcuctBrand>
            {wishListEnabled && displayable && !isSoldout && <WishListButton currentProduct={currentProduct} />}
          </ProductBrandWrapper>
          <ProductName>{getModelName(currentProduct)}</ProductName>
        </>
      ) : (
        <>
          <ProductBrandWrapper>
            <ProductCLName>{getModelName(currentProduct)}</ProductCLName>
          </ProductBrandWrapper>
          <ProductCLBrand>{getCLBrand(currentProduct) || t('ProductDetails.Labels.BrandName')}</ProductCLBrand>
        </>
      )}
    </ProdcuctNameWrapper>
  )
}
