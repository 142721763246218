import React, { FC, PropsWithChildren } from 'react'
import styled from '@mui/material/styles/styled'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

// Components
import WithLoading from '../../with-loading/WithLoading'

const StyledBreadcrumbsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: 0,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(12),
  },

  [theme.breakpoints.up('lg')]: {
    marginLeft: 0,
  },

  li: {
    margin: 0,
    fontSize: 12,
  },

  'li:nth-of-type(even)': {
    margin: `0 ${theme.spacing(1)}`,
  },
}))

const Breadcrumbs: FC<PropsWithChildren<{ isLoading: boolean }>> = ({ children, isLoading }) => {
  return (
    <StyledBreadcrumbsWrapper>
      <WithLoading height="24px" isLoading={isLoading}>
        <MuiBreadcrumbs>{children}</MuiBreadcrumbs>
      </WithLoading>
    </StyledBreadcrumbsWrapper>
  )
}

export default Breadcrumbs
