import {
  HeaderTitle,
  ProductDetailsAttributeContainer,
  ProductDetailsAttributeName,
  ProductDetailsAttributeValue,
  ProductDetailsDescriptionContainer,
  ProductDetailsListContainer,
  ProductDetailsListHeader,
  ProductDetailsListWrapper,
} from './ProductDetailsList.style'
import { ProductSoldOutStatus, IProduct, ServerProductAttribute } from '../../../types/product'
import { indexOf } from 'lodash-es'
import { isAccessories, isSun } from '../../../utils/product'
import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import React from 'react'
import WithSpinningLoader from '../../../components/with-loading/WithLoading'
import { getNormalizedProductType, getDrivingASNZS } from '../../../utils/productAttributes'
import theme from '../../../themes'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'next-i18next'
import ProductUnsuitableMessage from './ProductUnsuitableMessage'

export interface IProductDetailsList {
  isLoading: boolean
  currentProduct: IProduct
  type: ProductSoldOutStatus
}

type ProductAttribute = {
  name: string
  value: string
}

export const ProductDetailsList = ({ isLoading, currentProduct, type }: IProductDetailsList) => {
  const IS_NUMBER_REGEX = /\d+.\d/

  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))
  const mockAvailableState = true
  const { t: translate } = useTranslation()
  const keysToDisplay = [
    'FRAME_MATERIAL_CLASS',
    'FRAME_SHAPE', // MISSING
    'FRONT_COLOR',
    'FRAME_FITTING', // MISSING
    'GENDER',
    'CL_CORRECTION_TYPE', // Lens type
    'CL_MATERIAL_PATENTED', // Material
    'CL_BRAND', // Manufacturer
    'CL_VOLUME', // Volume for solutions
    'CL_WATER_CONTENT', // Water % of content
  ]
  const sunGlassesOnlyKeys = ['LENS_TREATMENT', 'LENS_COLOR']
  const showDrivingWarning = getDrivingASNZS(currentProduct) === false
  const productType = getNormalizedProductType(currentProduct)

  const filterProductAttribute = (attrKey: string) => {
    return attrKey && (keysToDisplay.includes(attrKey) || (isSun(productType) && sunGlassesOnlyKeys.includes(attrKey)))
  }

  const attributes: ProductAttribute[] =
    currentProduct &&
    currentProduct.productAttributes &&
    Object.keys(currentProduct.productAttributes)
      .filter(key => filterProductAttribute(key))
      .map(key => ({ name: key, value: currentProduct.productAttributes[key] }))
      .sort((a, b) => indexOf(keysToDisplay, a.name) - indexOf(keysToDisplay, b.name))
      .sort((a, b) => indexOf(sunGlassesOnlyKeys, a.name) - indexOf(sunGlassesOnlyKeys, b.name))

  const templeColorKey: string | undefined =
    currentProduct &&
    currentProduct.productAttributes &&
    Object.keys(currentProduct.productAttributes).filter(key => key === 'TEMPLE_COLOR')?.[0]
  const templeColor = currentProduct.productAttributes?.[templeColorKey]
  const getProductAttributeValue = (attribute: ProductAttribute): string => {
    const { name, value } = attribute
    if (name === 'FRONT_COLOR') {
      return value === templeColor ? value : templeColor ? `${value} - ${templeColor}` : value
    }
    if (name === 'CL_VOLUME') {
      return `${value} ml`
    }
    if (IS_NUMBER_REGEX.test(value)) {
      return translate('ProductDetails.Attributes.Percentage', { percent: parseInt(value) })
    }
    return value
  }
  return (type !== PRODUCT_SOLDOUT_STATUS.SOLDOUT && type !== PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK) ||
    mockAvailableState ? (
    <ProductDetailsListWrapper>
      <ProductDetailsListHeader>
        <HeaderTitle>{translate(['ProductDetails.Labels.AboutThisProduct', 'ABOUT THIS PRODUCT'])}</HeaderTitle>
      </ProductDetailsListHeader>
      <ProductDetailsListContainer>
        <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}>
          {attributes &&
            !isAccessories(productType) &&
            attributes.map(attr => (
              <ProductDetailsAttributeContainer key={attr.name}>
                <ProductDetailsAttributeName>
                  {translate(`ProductDetails.Attributes.${attr.name}`).toUpperCase().replace(/_/g, ' ')}:
                </ProductDetailsAttributeName>
                <ProductDetailsAttributeValue>{getProductAttributeValue(attr)}</ProductDetailsAttributeValue>
              </ProductDetailsAttributeContainer>
            ))}
          {showDrivingWarning && (
            <ProductUnsuitableMessage label={translate('ProductDetails.Labels.UnsuitableForDriving')} />
          )}
          <ProductDetailsDescriptionContainer
            dangerouslySetInnerHTML={{ __html: currentProduct.longDescription || '' }}
          />
        </WithSpinningLoader>
      </ProductDetailsListContainer>
    </ProductDetailsListWrapper>
  ) : null
}
