import { StyledLoader } from '@components/UI/StyledLoader'
import styled from '@mui/material/styles/styled'

export const VirtualMirrorWrapper = styled('div', {
  name: 'VirtualMirror',
  slot: 'Wrapper',
})(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: 'rgba(0,0,0,0.5)',
  display: 'grid',
  placeItems: 'center',
  fontSize: 16,

  '#vm-layout': {
    backgroundColor: theme.palette.text.light,
    maxHeight:'100vh',

    '.upload-image-container .upload-image-display-controls': {
      [theme.breakpoints.up('lg')]: {
        bottom: '-40%',
      },
    },
  },

  '.swiper-slide': {
    width: 'auto',
    height: 'auto',
  },

  '#bipa-overlay-root-style.bipa-overlay #bipa-component-container button': {
    color: theme.palette.primary.main,
    background: 'transparent',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      background: 'rgba(3, 84, 166, 0.1)',
      borderColor: theme.palette.primary.dark,
    },
  },
  '#bipa-overlay-root-style.bipa-overlay #bipa-component-container button.confirm': {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    '&:hover': {
      background: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
}))

export const VirtualMirrorContainer = styled('div', {
  name: 'VirtualMirror',
  slot: 'Container',
})(() => ({
  width: '100%',
  height: '100%',
}))

export const LoaderWrapper = styled('div', {
  name: 'VirtualMirror',
  slot: 'LoaderWrapper',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

export const Loader = styled(StyledLoader, {
  name: 'VirtualMirror',
  slot: 'Loader',
})(() => ({
  transform: 'translate(-50%,-50%)',
}))
