import { StyledTooltip } from '@components/UI/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styled from '@mui/material/styles/styled'
import { getIsGeoFit, getIsPhotochromic, getIsPolarized } from '@utils/productAttributes'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { IProduct } from '../../../types/product'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface IProductDetailsList {
  currentProduct: IProduct
}

export const StyledSpecialFrameInfoWrapper = styled('div', {
  name: 'SpecialFrameInfo',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: 12,
  color: theme.palette.text.dark.primary,
}))

const StyledProductSpecialAttributeSpan = styled('span', {
  name: 'SpecialFrameInfo',
  slot: 'AttributeSpan',
})(({ theme }) => ({
  display: 'flex',
  margin: `0 ${theme.spacing()} 0 ${theme.spacing()}`,

  span: {
    fontWeight: 'bold',
    marginRight: theme.spacing(),
  },
}))

interface IStyledProductSpecialAttributeProps {
  attr: string
}

const StyledProductSpecialAttribute = ({ attr }: IStyledProductSpecialAttributeProps) => {
  const { t } = useTranslation()

  const attrName = () => {
    switch (true) {
      case attr === 'universal fit' || attr === 'ajustement universel':
        return 'UNIVERSALFIT'
      case attr === 'low bridge fit' || attr === 'ajustement pour arête de nez basse':
        return 'LOWBRIDGEFIT'
      case attr === 'high bridge fit' || attr === 'ajustement pour arête de nez élevée':
        return 'HIGHBRIDGEFIT'
      case attr === 'adjustable nosepads' || attr === 'plaquettes réglables':
        return 'ADJUSTABLENOSEPADS'
      case attr === 'photochromic':
        return 'PHOTOCHROMIC'
      case attr === 'polarized':
        return 'POLARIZED'
      default:
        return attr
    }
  }
  const { isMobile, isTablet: isTabletPortrait, isTabletLandscape } = useBreakpoints()
  const touchableDevices = isMobile || isTabletPortrait || isTabletLandscape

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)
  const toggleTooltipOpen = () => setOpen(!open)

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledProductSpecialAttributeSpan>
        <span>{t(`ProductDetails.SpecialFrameInfo.Label.${attrName()}`)}</span>
        <StyledTooltip
          placement="top"
          contentVariant={{
            type: 'textonly',
            body: t(`ProductDetails.SpecialFrameInfo.Tooltip.${attrName()}`),
          }}
          colorVariant={'dark'}
          enterTouchDelay={0}
          open={open}
          onClick={touchableDevices ? toggleTooltipOpen : undefined}
          onMouseEnter={!touchableDevices ? handleTooltipOpen : undefined}
          onMouseLeave={!touchableDevices ? handleTooltipClose : undefined}
        >
          <div>
            <SVGIcon library="validation" name="info" />
          </div>
        </StyledTooltip>
      </StyledProductSpecialAttributeSpan>
    </ClickAwayListener>
  )
}

export const StyledSpecialFrameInfo = ({ currentProduct }: IProductDetailsList) => {
  const { t } = useTranslation()

  const geoFit = getIsGeoFit(currentProduct)
  const photochromic = getIsPhotochromic(currentProduct) ? 'photochromic' : ''
  const polarized = getIsPolarized(currentProduct) ? 'polarized' : ''

  const attributes = [geoFit, photochromic, polarized].filter(item => item)

  return attributes && attributes.length > 0 ? (
    <StyledSpecialFrameInfoWrapper>
      {t('ProductDetails.SpecialFrameInfo.Label.modelFeatures')}
      {attributes.map((attr, index) =>
        index >= 1 ? (
          <React.Fragment key={attr}>
            {' '}
            and <StyledProductSpecialAttribute key={`product-attribute-${attr}`} attr={attr.toLowerCase()} />
          </React.Fragment>
        ) : (
          <React.Fragment key={`product-attribute-wrapper-${attr}`}>
            {' '}
            <StyledProductSpecialAttribute key={`product-attribute-special-${attr}`} attr={attr.toLowerCase()} />
          </React.Fragment>
        )
      )}
    </StyledSpecialFrameInfoWrapper>
  ) : null
}
