import React, { CSSProperties } from 'react'
import styled from '@mui/material/styles/styled'

import { useTranslation } from 'next-i18next'

const ImageNotAvailablePlaceholderContainer = styled('div', {
  slot: 'ImageNotAvailable',
  name: 'Container'
})<{ small?: boolean }>(({ theme, small = false }) => (
  small ? {
    background: theme.palette.background.light.quinary,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    'div': {
      fontSize: 'unset',
    }
  }
  : {
    background: theme.palette.background.light.secondary,
    padding: `${theme.spacing(5)} 0`,
    margin: `${theme.spacing(4)} 0`,

    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(21),
      marginRight: theme.spacing(19.5),
      height: 336,
    },
  }
))

const ImageNotAvailableTitle = styled('div', {
  name: 'ImageNotAvailable',
  slot: 'Title'
})(({ theme }) => ({
  fontSize: theme.spacing(10),
  color: theme.palette.text.light.secondary,
}))

export interface ProductImageNotAvailableProps {
  small?: boolean,
  customStyle?: CSSProperties
}

const ProductImageNotAvailable: React.FC<ProductImageNotAvailableProps> = (props) => {
  const {small, customStyle} = props
  const { t } = useTranslation()

  return (
    <ImageNotAvailablePlaceholderContainer style={customStyle} small={small}>
      <ImageNotAvailableTitle>
        {t('ImageNotAvailable.title')}
      </ImageNotAvailableTitle>
    </ImageNotAvailablePlaceholderContainer>
  )
}

export default ProductImageNotAvailable
