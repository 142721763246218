import useTheme from '@mui/material/styles/useTheme'
import { useTranslation, i18n } from 'next-i18next'
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  useClearRefinements,
  useCurrentRefinements,
  useInstantSearch,
  useMenu,
  useSortBy,
} from 'react-instantsearch-hooks-web'
import { TransitionGroup } from 'react-transition-group'

import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import type { SearchResults } from 'algoliasearch-helper'
import useScrollTo from '../../../../hooks/useScrollTo'
import useScrollingUp from '../../../../hooks/useScrollingUp/useScrollingUp'
import useStickyScroll from '../../../../hooks/useStickyScroll'
import SizeAdvisorUtil from '../../../../utils/FrameGenius/SizeAdvisorUtil'
import { Pill } from '@components/UI/Pill'
import {
  ClearAllContainer,
  ClearAllLink,
  FilterCTASContainer,
  FilterCTASWrapper,
  FiltersAppliedContainer,
  ResultsNumberContainer,
  SquaredFilterPill,
  SquaredFilterPillWrapper,
  StyledFade,
  StyledFilterResultWrapper,
  StyledFilterWrapper,
  StyledPLPFiltersBar,
  StyledPLPFiltersBarButtonsWrapper,
  StyledPLPHeaderTitleWrapper,
  StyledPLPHeaderWrapper,
  StyledPLPOffersFiltersWrapper,
  StyledPLPSeparator,
  StyledPLPTitleSeparator,
  StyledResultsFor,
  StyledScrollToTopButton,
  StyledPlpHeaderWrapperMobile,
  StyledPlpHeaderWrapperDesktop,
} from './PlpHeader.style'
import PLPHeaderFilterAlgolia from './components/PlpHeaderFilterAlgolia'
import PlpHeaderTitle from './components/PlpHeaderTitle'

import { useProductParams } from '@hooks/useProductParams'
import { IAlgoliaHit } from '@typesApp/product'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { Refinement } from '@utils/Plp/PlpReducer'
import { flatRefinementList } from '@utils/Plp/Utils'
import { getFacetLabel } from '@utils/productAttributesAlgolia'
import { ICategory } from '@features/category/query'
import { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu'
import { TransformItems } from 'instantsearch.js/es/types/widget'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { useSearchParams } from 'next/navigation'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface PLPHeaderAlgoliaProps {
  title?: string
  catalogLoading?: boolean
  searchTerm?: string
  category: ICategory[] | null | undefined
  productData: any
  isPlacementsBetween?: boolean
  productTypePillFilters?: JSX.Element
  breadcrumbsList: any[]
  handleRoute?: (parameters: string[] | string) => void
  pathname?: string
}

const transformItems: TransformItems<MenuItem> = items => {
  return items
    .filter(el => +el.value >= 1)
    .sort((a, b) => Number(a.value) - Number(b.value))
    .map(item => {
      const _item = { ...item }
      const priceArray = _item.label.split('.')
      if (+priceArray[1] === 0) _item.label = priceArray[0]
      if (!_item.label.endsWith('OFF')) _item.label += i18n?.t('ProductGrid.Labels.off') ?? '% OFF'
      return _item
    })
}

const isDiscountFilterApplied = indexUiState => {
  return Object.keys(indexUiState?.menu || {}).some(key => key.includes('sortDiscount'))
}

const PlpHeaderAlgolia: FC<PLPHeaderAlgoliaProps> = ({
  title,
  catalogLoading,
  searchTerm,
  isPlacementsBetween,
  productTypePillFilters,
  breadcrumbsList,
  handleRoute,
  pathname,
}) => {
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const theme = useTheme()
  const searchParams = useSearchParams()
  const scrolled = useScrollingUp()
  const scrollTo = useScrollTo()
  const { t } = useTranslation()
  const { results, indexUiState } = useInstantSearch()
  const { items: currentRefinements } = useCurrentRefinements()
  const [sortDiscountAllSelected, setSortDiscountAllSelected] = useState<boolean>(isDiscountFilterApplied(indexUiState))
  const { refine, currentRefinement } = useSortBy({
    items: plpState.sortOrderOptions,
  })

  const { nbHits } = results as SearchResults<IAlgoliaHit>

  useEffect(() => {
    setSortDiscountAllSelected(isDiscountFilterApplied(indexUiState))
  }, [indexUiState])

  useEffect(() => {
    if (plpState.firstLoad || !plpState.openDrawerFilters) {
      plpDispatch({
        type: 'SET_MULTIPLE',
        payload: {
          _internalAppliedFacets: flatRefinementList(currentRefinements),
          appliedFacets: flatRefinementList(currentRefinements),
          shouldLoadUngrouped: false,
        },
      })
    }
  }, [currentRefinements, plpDispatch, plpState.firstLoad, plpState.openDrawerFilters])

  const [isFiltersBarStuck, setFiltersBarStuck] = useState<boolean>(false)
  const plpHeaderWrapperRef = useRef<HTMLDivElement | null>(null)
  const plpDesktopHeaderRef = useRef<HTMLDivElement | null>(null)
  const plpMobileHeaderRef = useRef<HTMLDivElement | null>(null)
  const filtersBarRef = useRef<HTMLDivElement>(null)

  const isSearchTerm = searchTerm !== ''
  const isSticky = useStickyScroll()

  const selectedFacets = plpState.appliedFacets.filter(el => !el.attribute.startsWith('attributes.'))
  const appliedFiltersNumberForSearch = selectedFacets.length

  const shouldShowActiveFilters = !(
    appliedFiltersNumberForSearch === 1 && selectedFacets[0].attribute.startsWith('sortDiscount_')
  )

  const onClearAll = (event: MouseEvent<HTMLButtonElement>) => {
    clearAll()
    handleRoute && handleRoute([])
    plpDispatch({
      type: 'SET_MULTIPLE',
      payload: {
        shouldLoadUngrouped: true,
      },
    })
    event?.preventDefault()
    SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
    setShouldUpdateDiscountFilters(true)
  }

  const handleRemoveFacet = (refinement: Refinement) => {
    refinement.refine(refinement)
    const params = new URLSearchParams(searchParams)
    params.delete(refinement.attribute)
    handleRoute && handleRoute(`${params.toString()}`)
    plpDispatch({
      type: 'SET_INTERNAL_APPLIED_FILTERS',
      payload: {
        _internalAppliedFacets: currentRefinements,
        shouldLoadUngrouped: true,
      },
    })
    SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
    setShouldUpdateDiscountFilters(true)
  }

  useEffect(() => {
    if (!filtersBarRef?.current) return
    const observer = new IntersectionObserver(([e]) => setFiltersBarStuck(e.intersectionRatio < 1), { threshold: 1 })
    observer.observe(filtersBarRef.current)
    return () => {
      if (filtersBarRef?.current) {
        observer.unobserve(filtersBarRef.current)
      }
    }
  }, [filtersBarRef])

  const customerSegment = useCustomerSegmentsUtil()[0]
  const { generateParameters } = useProductParams()

  const parameters = generateParameters(customerSegment)

  const { items, refine: applyDiscountFilter } = useMenu({
    attribute: `sortDiscount_${customerSegment}`,
    transformItems,
  })

  const [innerItems, setInnerItems] = useState(items)
  const [shouldUpdateDiscountFilters, setShouldUpdateDiscountFilters] = useState(true)

  const updateInnerItems = useCallback(
    (items: MenuItem[]): void => {
      if (plpState.firstLoad || plpState.shouldLoadUngrouped || shouldUpdateDiscountFilters) {
        setInnerItems(items)
        setShouldUpdateDiscountFilters(false)
      }
    },
    [plpState.firstLoad, plpState.shouldLoadUngrouped, shouldUpdateDiscountFilters]
  )

  useEffect(() => {
    updateInnerItems(items)
  }, [items, updateInnerItems])

  const { refine: clearSortDiscount } = useClearRefinements({
    includedAttributes: [`sortDiscount_${customerSegment}`],
  })

  const { refine: clearAll } = useClearRefinements()

  const removeDiscountFilter = useCallback(() => {
    setShouldUpdateDiscountFilters(true)
    clearSortDiscount()
  }, [clearSortDiscount])

  const isFiltersShown = appliedFiltersNumberForSearch > 0 && (shouldShowActiveFilters || !plpState.showOffersFilters)

  return (
    <StyledPLPHeaderWrapper ref={plpHeaderWrapperRef}>
      <StyledPlpHeaderWrapperMobile>
        <StyledPLPHeaderTitleWrapper
          isSticky={isSticky}
          isScrolled={!!scrolled}
          width={plpHeaderWrapperRef.current?.clientWidth}
          withFilters={appliedFiltersNumberForSearch > 0}
          ref={plpMobileHeaderRef}
        >
          {isPlacementsBetween && !isSticky && (
            <PlpHeaderTitle
              isSticky={isSticky}
              catalogLoading={catalogLoading}
              text={
                searchTerm
                  ? t('ProductGrid.Labels.searchFor', {
                      searchTerm: searchTerm.replace('*', ''),
                    })
                  : title
              }
            />
          )}
          {plpState.showOffersFilters && !isSticky && innerItems.length > 0 && (
            <>
              <StyledPLPSeparator />
              <StyledPLPOffersFiltersWrapper>
                <SquaredFilterPillWrapper>
                  <SquaredFilterPill
                    selected={!sortDiscountAllSelected}
                    labelText={t('ProductGrid.Labels.all')}
                    variant={!parameters.discount ? 'tertiary' : 'secondary'}
                    onClick={removeDiscountFilter}
                  />
                </SquaredFilterPillWrapper>
                {innerItems.map(item => (
                  <SquaredFilterPillWrapper key={item.value}>
                    <SquaredFilterPill
                      labelText={item.label}
                      selected={item.isRefined}
                      variant={parameters?.discount === item.value ? 'tertiary' : 'secondary'}
                      onClick={() => {
                        applyDiscountFilter(item.value)
                        setShouldUpdateDiscountFilters(true)
                      }}
                    />
                  </SquaredFilterPillWrapper>
                ))}
              </StyledPLPOffersFiltersWrapper>
            </>
          )}
          {!isSticky && <StyledPLPTitleSeparator />}
          {productTypePillFilters}
          <PLPHeaderFilterAlgolia
            ref={plpMobileHeaderRef}
            appliedFiltersNumber={appliedFiltersNumberForSearch}
            catalogLoading
            productTotal={nbHits}
            refine={refine}
            currentRefinement={currentRefinement}
            breadcrumbsList={breadcrumbsList}
          />
        </StyledPLPHeaderTitleWrapper>

        <StyledPLPSeparator />

        <StyledPLPFiltersBar
          isStuck={isFiltersBarStuck}
          ref={filtersBarRef}
          isFiltersShown={isFiltersShown}
          isMobileContainer={true}
        >
          <StyledPLPFiltersBarButtonsWrapper>
            <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
              <ResultsNumberContainer>
                <StyledResultsFor>{t('ProductGrid.Labels.resultsFor', { total: nbHits })}</StyledResultsFor>
              </ResultsNumberContainer>
              {isFiltersShown && (
                <FilterCTASContainer>
                  <FiltersAppliedContainer>
                    <TransitionGroup component={null}>
                      {plpState.showOffersFilters
                        ? selectedFacets
                            .filter(el => !el.attribute.startsWith('sortDiscount_'))
                            .map(facet => {
                              let { label } = facet
                              label = getFacetLabel(label)
                              return (
                                <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                  <div>
                                    <Pill
                                      labelText={label + 'top'}
                                      endIcon={
                                        <SVGIcon
                                          library="close"
                                          name="close-circle"
                                          onClick={() => {
                                            handleRemoveFacet(facet)
                                          }}
                                        />
                                      }
                                      variant={'secondary'}
                                    />
                                  </div>
                                </StyledFade>
                              )
                            })
                        : selectedFacets.map(facet => {
                            let { label } = facet
                            label = getFacetLabel(label)
                            if (facet.attribute.startsWith('sortDiscount_')) {
                              const priceArray = facet.label.split('.')
                              label = `${+priceArray[1] === 0 ? priceArray[0] : label}% OFF`
                            }
                            return (
                              <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                <div>
                                  <Pill
                                    labelText={label}
                                    endIcon={
                                      <SVGIcon
                                        library="close"
                                        name="close-circle"
                                        onClick={() => {
                                          handleRemoveFacet(facet)
                                        }}
                                      />
                                    }
                                    variant={'secondary'}
                                  />
                                </div>
                              </StyledFade>
                            )
                          })}
                    </TransitionGroup>
                    {appliedFiltersNumberForSearch > 0 && (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                      <ClearAllContainer>
                        <ClearAllLink
                          onClick={event => {
                            onClearAll(event)
                          }}
                        >
                          {t('ProductGrid.Actions.clearAll')}{' '}
                        </ClearAllLink>
                      </ClearAllContainer>
                    )}
                  </FiltersAppliedContainer>
                </FilterCTASContainer>
              )}
            </FilterCTASWrapper>
          </StyledPLPFiltersBarButtonsWrapper>
        </StyledPLPFiltersBar>
      </StyledPlpHeaderWrapperMobile>

      <StyledPlpHeaderWrapperDesktop>
        <StyledPLPHeaderTitleWrapper
          isSticky={isSticky}
          isScrolled={!!scrolled}
          width={plpHeaderWrapperRef.current?.clientWidth}
          ref={plpDesktopHeaderRef}
        >
          <PlpHeaderTitle
            isSticky={isSticky}
            text={
              searchTerm
                ? t('ProductGrid.Labels.searchFor', {
                    searchTerm: searchTerm.replace('*', ''),
                  })
                : title
            }
          />

          <StyledFilterResultWrapper>
            {productTypePillFilters}
            {((!isSearchTerm &&
              nbHits > 0 &&
              (appliedFiltersNumberForSearch === 0 || (!shouldShowActiveFilters && plpState.showOffersFilters))) ||
              isSticky) && (
              <StyledFilterWrapper>
                <PLPHeaderFilterAlgolia
                  catalogLoading={catalogLoading}
                  appliedFiltersNumber={appliedFiltersNumberForSearch}
                  productTotal={nbHits}
                  refine={refine}
                  currentRefinement={currentRefinement}
                  breadcrumbsList={breadcrumbsList}
                />
              </StyledFilterWrapper>
            )}

            {isSticky && (
              <StyledScrollToTopButton aria-label="ScrollToTop" onClick={() => scrollTo(0, 0)}>
                <SVGIcon library="arrow" name="arrow-up" color={theme.palette.custom.cyprus} />
              </StyledScrollToTopButton>
            )}
          </StyledFilterResultWrapper>
        </StyledPLPHeaderTitleWrapper>

        {plpState.showOffersFilters && innerItems.length > 0 && (
          <StyledPLPOffersFiltersWrapper>
            <SquaredFilterPillWrapper>
              <SquaredFilterPill
                selected={!sortDiscountAllSelected}
                labelText={t('ProductGrid.Labels.all')}
                variant={!parameters.discount ? 'tertiary' : 'secondary'}
                onClick={removeDiscountFilter}
              />
            </SquaredFilterPillWrapper>
            {innerItems.map(item => (
              <SquaredFilterPillWrapper key={item.value}>
                <SquaredFilterPill
                  labelText={item.label}
                  selected={item.isRefined}
                  variant={parameters?.discount === item.value ? 'tertiary' : 'secondary'}
                  onClick={() => {
                    applyDiscountFilter(item.value)
                    setShouldUpdateDiscountFilters(true)
                  }}
                />
              </SquaredFilterPillWrapper>
            ))}
          </StyledPLPOffersFiltersWrapper>
        )}

        <StyledPLPSeparator />

        <div id="filterbar" />
        <ClientOnlyPortal selector={'#filterbar'}>
          <StyledPLPFiltersBar isStuck={isFiltersBarStuck} ref={filtersBarRef} isFiltersShown={isFiltersShown}>
            <StyledPLPFiltersBarButtonsWrapper>
              <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
                {shouldShowActiveFilters && (
                  <ResultsNumberContainer>
                    <StyledResultsFor>{t('ProductGrid.Labels.resultsFor', { total: nbHits })}</StyledResultsFor>
                  </ResultsNumberContainer>
                )}
                {(searchTerm !== '' || (appliedFiltersNumberForSearch > 0 && searchTerm === '')) &&
                  (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                    <FilterCTASContainer>
                      {appliedFiltersNumberForSearch > 0 && (
                        <FiltersAppliedContainer>
                          <TransitionGroup component={null}>
                            {plpState.showOffersFilters
                              ? plpState.appliedFacets
                                  .filter(el => !el.attribute.startsWith('sortDiscount_'))
                                  .map(refinement => {
                                    let { label } = refinement
                                    label = getFacetLabel(label)
                                    return (
                                      <StyledFade key={refinement.value} timeout={{ exit: 500 }}>
                                        <div>
                                          <Pill
                                            labelText={label}
                                            endIcon={
                                              <SVGIcon
                                                library="close"
                                                name="close-circle"
                                                onClick={() => handleRemoveFacet(refinement)}
                                              />
                                            }
                                            variant={'secondary'}
                                          />
                                        </div>
                                      </StyledFade>
                                    )
                                  })
                              : selectedFacets.map(facet => {
                                  let { label } = facet
                                  label = getFacetLabel(label)
                                  if (facet.attribute.startsWith('sortDiscount_')) {
                                    const priceArray = facet.label.split('.')
                                    label = `${+priceArray[1] === 0 ? priceArray[0] : label}% OFF`
                                  }
                                  return (
                                    <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                      <div>
                                        <Pill
                                          labelText={label}
                                          endIcon={
                                            <SVGIcon
                                              library="close"
                                              name="close-circle"
                                              onClick={() => handleRemoveFacet(facet)}
                                            />
                                          }
                                          variant={'secondary'}
                                        />
                                      </div>
                                    </StyledFade>
                                  )
                                })}
                          </TransitionGroup>
                          {appliedFiltersNumberForSearch > 0 &&
                            (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                              <ClearAllContainer>
                                <ClearAllLink onClick={event => onClearAll(event)}>
                                  {t('ProductGrid.Actions.clearAll')}{' '}
                                </ClearAllLink>
                              </ClearAllContainer>
                            )}
                        </FiltersAppliedContainer>
                      )}
                      {(isSearchTerm || appliedFiltersNumberForSearch > 0) &&
                        (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                          <PLPHeaderFilterAlgolia
                            appliedFiltersNumber={appliedFiltersNumberForSearch}
                            catalogLoading={catalogLoading}
                            refine={refine}
                            currentRefinement={currentRefinement}
                            breadcrumbsList={breadcrumbsList}
                          />
                        )}
                    </FilterCTASContainer>
                  )}
              </FilterCTASWrapper>
            </StyledPLPFiltersBarButtonsWrapper>
          </StyledPLPFiltersBar>
        </ClientOnlyPortal>
      </StyledPlpHeaderWrapperDesktop>
    </StyledPLPHeaderWrapper>
  )
}

export default PlpHeaderAlgolia
