import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

export type SubscribeToAvailableNotificationProps = {
  transactionContext: string
  storeID: string
  emailAddress: string
  catentryId: string
}
/**
 * @deprecated Use Redux Toolkit Query instead
 */
const ProductAvailableNotification = {
  subscribeToAvailableNotification(args: SubscribeToAvailableNotificationProps): AxiosPromise<any> {
    const {
      storeID,
      transactionContext,
      ...data
    } = args

    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `${transactionContext}/store/${storeID}/itemAvailabilityNotify`,
      data,
    }
    return axios(requestOptions)
  },
}

export default ProductAvailableNotification
