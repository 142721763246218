import { useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { StyledTypography } from '../../../../components/UI'

export const BridgeGuide = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.text.dark.primary}
      >
        {t('SizeFitGuide.bridge.title')}
      </StyledTypography>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.text.dark.primary}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.bridge.description1'),
        }}
      />
    </div>
  )
})
