import * as React from 'react'

import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductColorSelector from '../ProductColorSelector'
import ProductCtaContainer from '../ProductCtaContainer'
import { ProductDetailsList } from '../ProductDetailsList'
import { ProductNameSection } from '../ProductNameSection'
import { ProductRightColumnProps } from '@typesApp/product'
import { StyledSeparator } from '../../ProductDetails.style'
import { SpecialFrameInfo } from '../SpecialFrameInfo'
import { getFrontColor, getSoldOut } from '../../../../utils/productAttributes'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'
import ProductPriceAlgolia from '../ProductPriceAlgolia'
import {
  determineAlgoliaPrice,
  getProductAlgoliaPrice,
  shouldShowAbsoluteDiscount,
} from '../../../../foundation/algolia/algoliaPrice'
import { getCustomerSegmentsfromCookie } from '../../../../features/plp/algoliaUtils'
import { TrustPilotMiniWidget } from '@components/TrustPilotReviews/TrustPilotWidget'
import useBreakpoints from '@hooks/useBreakpoints'

export const AccessoriesPdpRightColumn = (props: ProductRightColumnProps) => {
  const {
    isLoading,
    partNumber,
    currentProduct,
    soldOutStatus,
    productInCart,
    addToCartButtonFail,
    ctaRef,
    placements,
    cluster,
    onClusterProductClick,
    isSoldout,
    isRoxable,
    customerSegments,
    productQuantity,
  } = props
  const { isDesktop } = useBreakpoints()
  const customerSegment = getCustomerSegmentsfromCookie()
  const frontColor = getFrontColor(currentProduct)
  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false
  const displayable = currentProduct.displayable

  const algoliaPrices = getProductAlgoliaPrice(customerSegment, currentProduct)

  return (
    <>
      {currentProduct && <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} />}
      {isDesktop && currentProduct && <TrustPilotMiniWidget sku={partNumber} />}
      {currentProduct && !isSoldout ? (
        <>
          <SpecialFrameInfo currentProduct={currentProduct} />
          <StyledSeparator />
        </>
      ) : null}
      {cluster && cluster.length >= 0 && !areAllClusterItemsSoldOut && (
        <>
          <ProductColorSelector
            currentProductItem={currentProduct}
            isLoading={isLoading}
            soldOutStatus={soldOutStatus}
            cluster={cluster}
            selectedPartNumber={partNumber}
            onClick={onClusterProductClick}
          />
          {(cluster?.length >= 0 || frontColor) && <StyledSeparator />}
        </>
      )}
      {currentProduct && displayable && currentProduct.x_price && (
        <ProductPriceAlgolia
          isLoading={isLoading}
          productType={PRODUCT_TYPES_KEYS.ACCESSORIES}
          price={determineAlgoliaPrice(currentProduct?.x_price, customerSegments, PRODUCT_TYPES_KEYS.ACCESSORIES)}
          type={soldOutStatus}
          isPDP={true}
          isRoxable={isRoxable}
          isAbsoluteDiscount={shouldShowAbsoluteDiscount(currentProduct)}
        />
      )}
      {currentProduct && displayable && (
        <ProductCtaContainer
          productInCart={productInCart}
          isLoading={isLoading}
          soldOutStatus={soldOutStatus}
          currentProduct={currentProduct}
          error={addToCartButtonFail}
          ctaRef={ctaRef}
          roxable={isRoxable}
          addToCartFillType={'fill'}
          productQuantity={productQuantity}
          algoliaPrices={algoliaPrices}
        />
      )}
      <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.ACCESSORIES} />
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.attributes || []}
        product={currentProduct}
        soldOutStatus={soldOutStatus}
      />
      {currentProduct && (
        <ProductDetailsList type={soldOutStatus} isLoading={isLoading} currentProduct={currentProduct} />
      )}
    </>
  )
}
