import React from 'react'
import { useTranslation } from 'next-i18next'

import { SizeAdvisorSwitcher } from './SizeAdvisorSwitcher'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { StyledTypography } from '../../UI'
import { StyledSuggestedFiltersContainer } from '../PlpFilter.style'
import { StyledSizeAdvisorDescr, StyledFrameGeniusPhoto, StyledSizeAdvisorSwitcherContainer } from './SizeAdvisorSuggestion.style'

export interface ISizeAdvisorSuggestion {
  toggleHingesFacet: () => void
  enabled: boolean
}

export const SizeAdvisorSuggestion: React.FC<ISizeAdvisorSuggestion> = ({
  toggleHingesFacet,
  enabled,
}) => {
  const frameGeniusData = useFrameGenius()
  const { t } = useTranslation()

  return (
    <StyledSuggestedFiltersContainer>
      <StyledSizeAdvisorDescr>
        <StyledFrameGeniusPhoto
          url={frameGeniusData?.analysisResults?.profileImageUrl}
        />
        <div>
          <StyledTypography fontWeight="bold" isUppercased fontSize={14}>
            {t('ProductFilter.SizeAdvisor.title')}
          </StyledTypography>
          <StyledTypography fontSize={12}>
            {t('ProductFilter.SizeAdvisor.subTitle')}
          </StyledTypography>
        </div>
      </StyledSizeAdvisorDescr>
      <StyledSizeAdvisorSwitcherContainer>
        <StyledTypography fontSize={14}>
          {t('ProductFilter.SizeAdvisor.switcherLabel')}
        </StyledTypography>
        <SizeAdvisorSwitcher
          toggleHingesFacet={toggleHingesFacet}
          enabled={enabled}
        />
      </StyledSizeAdvisorSwitcherContainer>
    </StyledSuggestedFiltersContainer>
  )
}
