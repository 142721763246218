import { useState } from 'react'
import { useMenu } from 'react-instantsearch-hooks-web'
import { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu'
import { TransformItems } from 'instantsearch.js/es/types/widget'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const FACET_PRODUCT_TYPE = 'attributes.LX_SEARCH_PAGE_PRODUCT_TYPE'

const transformItems: TransformItems<MenuItem> = items => {
  return items.sort((a, b) => a.label.localeCompare(b.label))
}

const useProductTypePillFilters = () => {
  const { items, refine } = useMenu({
    attribute: FACET_PRODUCT_TYPE,
    transformItems,
  })

  const [selectedPT, setSelectedPT] = useState<string>('')

  const getPTIcon = (type: string): JSX.Element => {
    switch (type) {
      case 'contactLenses':
        return <SVGIcon library="filter" name="contact-lens" size={18} />
      case 'accessories':
        return <SVGIcon library="filter" name="glasses-case" size={25} />
      case 'contactLensesAccessories':
        return <SVGIcon library="filter" name="cl-accessories" size={18} />
      default:
        return <SVGIcon library="filter" name="glasses" size={18} />
    }
  }

  const handleOnClickPT = (pt: string) => {
    setSelectedPT(selectedPT === pt ? '' : pt)
    refine(pt)
  }

  return { items, selectedPT, getPTIcon, handleOnClickPT }
}

export default useProductTypePillFilters
