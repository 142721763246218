import styled from '@mui/material/styles/styled'

export const StyledShippingTimesBanner = styled('div', {
  name: 'ShippingTimes',
  slot: 'Banner',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  gap: '1rem',
  marginTop: theme.spacing(4),
}))

export const StyledShippingTimesTitle = styled('span', {
  name: 'ShippingTimes',
  slot: 'Title',
})(() => ({
  fontSize: '14px',
  fontWeight: 700,
}))

export const StyledShippingTimesContentItem = styled('p', {
  name: 'ShippingTimes',
  slot: 'ContentItem',
})(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.custom.light1.grey,

  '&:first-of-type': {
    marginTop: 0,
  },

  '&:last-child': {
    marginBottom: 0,
  },
}))
