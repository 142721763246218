import React from 'react'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const FilterIcons: React.FC<{ teaserIcon: string }> = ({ teaserIcon }) => {
  switch (teaserIcon) {
    case 'Square':
      return <SVGIcon library="frame" name="square" />
    case 'Cat Eye':
      return <SVGIcon library="frame" name="cat-eye" />
    case 'Rectangle':
      return <SVGIcon library="frame" name="rectangle" />
    case 'Irregular':
      return <SVGIcon library="frame" name="irregular" />
    case 'Phantos':
      return <SVGIcon library="frame" name="panthos" />
    case 'Pillow':
      return <SVGIcon library="frame" name="pillow" />
    case 'Round':
      return <SVGIcon library="frame" name="round" />
    case 'Oval':
      return <SVGIcon library="frame" name="oval" />
    case 'Pilot':
      return <SVGIcon library="frame" name="pilot" />
    case 'Hexagonal':
      return <SVGIcon library="frame" name="hexagonal" />
    case 'Butterfly':
      return <SVGIcon library="frame" name="butterfly" />
    case 'Geometrical':
      return <SVGIcon library="frame" name="geometrical" />
    default:
      return null
  }
}

export default FilterIcons
