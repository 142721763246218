import { TFunction, useTranslation } from 'next-i18next'
import React from 'react'

import Faqs, { AccordionArticle } from '@components/Cms/CmsPlacement/CmsBanner/Faqs/Faqs'
import TextModuleArticle from '@components/Cms/CmsPlacement/CmsBanner/TextModule/components/TextModuleArticle'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { ICMCollectionFaqs, IPlacement, IPlacementItem, isCMArticle, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { PlpDescriptionAccordionWrapper, PlpDescriptionFaqsWrapper } from './PlpDescription.style'

const formatPlacementForArticle = (placement: ICMArticle): ICMArticle | null => {
  const clonedArticle = { ...placement }
  clonedArticle.detailText = placement.teaserText ? placement.teaserText : ''
  clonedArticle.teaserText = placement.detailText
  return clonedArticle
}

const formatPlacementForFaqs = (placement: ICMCollectionFaqs): IPlacement | null => {
  const items = [...placement.teasableItems]
  items.push({
    teaserTitle1: placement.collectionTitle,
    teaserTitle2: placement.collectionSubTitle,
    teaserText1: placement.collectionText,
    name: placement.name,
    promoteToH1: true,
  } as ICMCollectionFaqs)
  return {
    collectionTitle: placement.collectionTitle,
    collectionText: placement.collectionText,
    collectionSubTitle: placement.collectionSubTitle,
    viewtype: placement.viewtype,
    name: placement.name,
    marginLateral: false,
    teaserLXCallToActionSettings: placement.teaserLXCallToActionSettings!,
    placementReflect: false,
    marginVertical: 'S',
    backgroundColor: '',
    clusterTile: false,
    placementAnimation: '',
    items: items,
  }
}

const formatPlacementForAccordionArticle = (placement: ICMArticle | undefined, t: TFunction): ICMArticle | null => {
  const clonedArticle = { ...placement } as ICMArticle
  if (clonedArticle.detailText?.length === 0) return null
  clonedArticle.title = t('PlpDescription.Labels.ReadMore')
  return clonedArticle
}

export interface IPlpDescriptionProps {
  placements: IPlacementItem[]
}

const PlpDescription: React.FC<IPlpDescriptionProps> = ({ placements }) => {
  const { t } = useTranslation()
  const unformattedArticle = placements.find(isCMArticle)
  const unformattedCollection = placements.find(isCMCollection) as ICMCollectionFaqs

  const article = unformattedArticle ? formatPlacementForArticle(unformattedArticle) : null
  const collection = unformattedCollection ? formatPlacementForFaqs(unformattedCollection) : null

  const accordionArticle = formatPlacementForAccordionArticle(placements.find(isCMArticle), t)

  return (
    <>
      {article && (
        <>
          <TextModuleArticle item={article} />
          {accordionArticle && (
            <PlpDescriptionAccordionWrapper>
              <AccordionArticle article={accordionArticle} />
            </PlpDescriptionAccordionWrapper>
          )}
        </>
      )}
      {collection && (
        <PlpDescriptionFaqsWrapper>
          <Faqs placement={collection} />
        </PlpDescriptionFaqsWrapper>
      )}
    </>
  )
}
export default PlpDescription
