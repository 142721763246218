import { ICategory } from '@features/category/query'
//Standard libraries
import React, { useEffect, useRef, useState } from 'react'
import { PDPPageData, getFrameAdvisorAnalyticsData, sendPdpEvent } from '../../../foundation/analytics/tealium/lib'
import { useDispatch, useSelector } from 'react-redux'
import ProductDetails from '@views/ProductDetails'
import { IProduct } from '@typesApp/product'
//Redux
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'
import styled from '@mui/material/styles/styled'
import { loginStatusSelector } from '@redux/selectors/user'
//Foundation libraries
import usePdpPlacements from './usePdpPlacements'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { initMonetateHistoryTracker } from '@foundation/monetate/monetateUtil'
import {
  getCurrentProductItem,
  getParentCatalogGroupId,
  isCLAccessories,
  isFrames,
  isOptical,
  isSun,
  isVirtualMirrorEligible,
} from '@utils/product'
import { useGetAvailabilityQuery } from '@features/virtualMirror/query'
import useProductData from '@hooks/useProductData/useProductData'
import { getProductType } from '@utils/productAttributes'
import { useFrameGenius } from '@hooks/useFrameGenius'
import FrameAdvisorUtil from '@utils/FrameGenius/FrameAdvisorUtil'
import { useProductSoldOutState } from '@views/ProductDetails/useProductSoldOutState'
import { isVMMVEnabledSelector } from '@redux/selectors/site'
import { PRODUCT_SOLDOUT_STATUS } from '@constants/product'
import { useGetLiveStockQuery } from '@features/liveStockCheck/query'
import { useSite } from '@foundation/hooks/useSite'
import config from '@configs/config.base'
import { buildQueryParams } from '@features/liveStockCheck/utils'
import { addCLAccessoriesToCartErrorSelector } from '@features/product/selector'
import { MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY } from '@constants/order'
import { useNavigateBack } from '@hooks/useNavigateBack'
import { toggleAddClAccessoriesToCartError } from '@features/product/slice'

const StyledProductContainer = styled('div', {
  name: 'ProductContainer',
  slot: 'MainContainer',
})({
  width: '100%',
})

export type Breadcrumbs = {
  label: string
  seo: {
    href: string
  }
  value: string
  categoryIdentifier: string
}

export const getProductBreadcrumbs = (categoryData?: ICategory[] | null): Breadcrumbs[] | undefined => {
  return categoryData?.map(el => ({
    label: el.description,
    seo: el.seo,
    value: el.uniqueID,
    categoryIdentifier: el.identifier,
  }))
}

export const sortBreadcrumbsByGroupId = (
  breadcrumbsNotSorted: Breadcrumbs[],
  parentCatalogGroupID?: string[] | null
): Breadcrumbs[] => {
  return (
    parentCatalogGroupID?.reduce<Breadcrumbs[]>((acc, groupId) => {
      const i = breadcrumbsNotSorted?.find(br => br.value === groupId)
      if (i) {
        acc.push(i)
      }
      return acc
    }, []) || []
  )
}

/**
 * Product page component
 * @param props
 */
type ProductProps = {
  categoryData: ICategory[] | null
  pdpProduct: IProduct
  page: {
    externalContext: {
      identifier: string
    }
  }
}

const Product: React.FC<ProductProps> = props => {
  const { mySite } = useSite()
  const dispatch = useDispatch()

  const { ...analyticsDataForPdp } = useAnalyticsData('pdp')
  const loginStatus = useSelector(loginStatusSelector)
  const { categoryData, pdpProduct: pdpData } = props
  const { page } = props
  const [partNumber, setPartNumber] = useState<string>(page.externalContext.identifier)
  const frameGeniusData = useFrameGenius()
  const analyticsPdpPageData = useRef<PDPPageData>()

  const breadcrumbsNotSorted = getProductBreadcrumbs(categoryData) || []
  const parentCatalogGroupID = getParentCatalogGroupId(categoryData) || []
  const breadcrumbs = sortBreadcrumbsByGroupId(breadcrumbsNotSorted, parentCatalogGroupID)

  //get placements cms pdp
  const { placementsPdpCommerce, loadingCommercePlacements } = usePdpPlacements(pdpData, breadcrumbs)

  // dynamic content
  const { dynamicContentBanners } = useDynamicCmsContent(placementsPdpCommerce, pdpData)
  const { suggestedProducts, currentProductItem: currentProduct, isRoxable } = useProductData(pdpData, partNumber)
  const currentProductPartNumber = currentProduct?.items?.[0]?.partNumber
  const productType = currentProduct && getProductType(currentProduct)
  const isFramePage = productType && (isSun(productType) || isOptical(productType) || isFrames(productType))
  const isCLAccessory = isCLAccessories(productType)

  const { soldOutStatus } = useProductSoldOutState({
    currentProductPartNumber: partNumber,
    product: getCurrentProductItem(partNumber, pdpData?.cluster || []) || undefined,
  })

  /**
   * LiveStock for CL accessories
   */
  const isLiveStockEnabled = config.isClAccessoriesStockCheckEnabled && isCLAccessory
  const liveStockQueryParams = buildQueryParams(mySite.storeID, [currentProductPartNumber ?? ''])
  const {
    data: liveStockData,
    isLoading: isLiveStockLoading,
    refetch: refetchLiveStock,
  } = useGetLiveStockQuery(liveStockQueryParams, {
    skip: !isLiveStockEnabled,
  })
  const { liveStockOutOfStockProducts, results } = liveStockData ?? {}
  const addCLAccessoriesToCartError = useSelector(addCLAccessoriesToCartErrorSelector)
  const isCLAccessoryOutOfStock =
    liveStockOutOfStockProducts?.includes(currentProductPartNumber ?? '') && !addCLAccessoriesToCartError
  const liveStockSoldOutStatus = isCLAccessoryOutOfStock ? PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK : soldOutStatus
  const liveStockAvailableQuantity = isCLAccessoryOutOfStock ? 0 : Number(results?.[0]?.Available)
  const availableQuantity = Math.min(liveStockAvailableQuantity, MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY)

  const analyticsDataReady = !isCLAccessory || (isCLAccessory && !isLiveStockLoading)

  const firstload = useRef(true)
  const isSoldout = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT

  const isVMMVEnabled = useSelector(isVMMVEnabledSelector)
  const { data: vmProductData } = useGetAvailabilityQuery(
    { upc: currentProduct?.partNumber || '' },
    { skip: !currentProduct?.partNumber || !isFramePage || !isVMMVEnabled }
  )
  const updateAnalyticsPageData = (p: IProduct) => {
    const filters = sessionStorage.getItem('PLPFiltersApplied')
    analyticsDataForPdp.userToken = getUserToken()
    const isFrameAdvisorMinimized = FrameAdvisorUtil.isFloatingButtonMinimized()

    analyticsPdpPageData.current = {
      common: {
        ...analyticsDataForPdp,
        ...(isFrameAdvisorMinimized && getFrameAdvisorAnalyticsData(frameGeniusData)),
      },
      loginStatus,
      soldOutStatus: liveStockSoldOutStatus,
      products: [p],
      pageSection1: currentProduct?.seo?.href || '',
      pageSection2: currentProduct?.name || '',
      isUpcSupported: isVirtualMirrorEligible(currentProduct) && pdpData && !isSoldout ? '1' : '0',
      // TODO: add proper verification of virtual mirror browser support, hardcoding until information is available
      isBrowserSupported: '1',
      vmIsSupported: vmProductData?.available ? '1' : '2',
      grouped_queryID: sessionStorage.getItem('grouped_queryID'),
      ungrouped_queryID: sessionStorage.getItem('ungrouped_queryID'),
      grouped_indexName: sessionStorage.getItem('grouped_indexName'),
      ungrouped_indexName: sessionStorage.getItem('ungrouped_indexName'),
      position: sessionStorage.getItem('grouped_position'),
      ungrouped_position: sessionStorage.getItem('ungrouped_position'),
      objectID: sessionStorage.getItem('ungrouped_objectID'),
      filters: filters && JSON.parse(filters),
    }
  }

  useNavigateBack()

  useEffect(() => {
    updateAnalyticsPageData(pdpData)
  }, [
    analyticsDataForPdp,
    currentProduct,
    frameGeniusData,
    isVMMVEnabled,
    loginStatus,
    partNumber,
    soldOutStatus,
    vmProductData,
  ])

  useEffect(() => {
    if (firstload.current && analyticsPdpPageData.current && analyticsDataReady) {
      sendPdpEvent(analyticsPdpPageData.current)
      firstload.current = false

      initMonetateHistoryTracker()
    }
  }, [analyticsDataReady])

  const onClusterProductClick = (p: IProduct) => {
    updateAnalyticsPageData(p)
    if (analyticsPdpPageData.current) sendPdpEvent(analyticsPdpPageData.current)

    const partNumberToSet = p.items?.[0]?.partNumber

    if (partNumberToSet) {
      setPartNumber(partNumberToSet)
    }
  }

  useEffect(() => {
    setPartNumber(page.externalContext.identifier)

    // reset error on load
    dispatch(toggleAddClAccessoriesToCartError(null))
  }, [page.externalContext.identifier])

  if (!pdpData || !currentProduct) {
    return null
  }

  useEffect(() => {
    if (addCLAccessoriesToCartError && isLiveStockEnabled && !isLiveStockLoading) {
      refetchLiveStock()
    }
  }, [addCLAccessoriesToCartError, isLiveStockLoading])

  // TODO if breadcrumb will be need, just copy content from topMarketingSection
  return (
    <StyledProductContainer id={page.externalContext.identifier}>
      <ProductDetails
        onClusterProductClick={onClusterProductClick}
        partNumber={partNumber}
        pdpData={pdpData}
        placements={dynamicContentBanners}
        categoryData={categoryData || undefined}
        breadcrumbs={breadcrumbs}
        loading={false}
        loadingCommercePlacements={loadingCommercePlacements}
        isVmProduct={!!vmProductData?.available}
        currentProduct={currentProduct}
        isRoxable={isRoxable}
        suggestedProducts={suggestedProducts}
        analyticsPdpData={analyticsPdpPageData.current}
        availableQuantity={availableQuantity}
        soldOutStatus={liveStockSoldOutStatus}
        liveStockError={!!addCLAccessoriesToCartError}
      />
    </StyledProductContainer>
  )
}

export default Product
