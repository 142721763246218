import React, { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Pagination } from 'swiper'
// TYPES
import { Attachment } from '../../../types/product'

import {
  ProductFullPageGalleryContainer,
  ProductFullPageGalleryCloseIcon,
} from './ProductImagesSlider/ProductImagesSlider.style'

import { ProductFullPageGalleryThumbsSlider, ProductFullPageGalleryImagesSlider } from '../ProductDetails.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
//UI

/**
 * @param { Attachment[] } images slider images
 * @param { boolean } isVisible toggle the component visibility
 * @param { boolean } pdpDataloading pdp data loading status
 * @param { function } onCloseClick callback when clicking on close button
 * @param { number } currentImageIndex you can force the slide index to the slider
 */

export interface ProductFullPageGalleryProps {
  alt?: string
  currentImageIndex?: number
  images?: Attachment[]
  isVisible?: boolean
  onCloseClick?: () => void
  pdpDataloading?: boolean
}

const ProductFullPageGallery: FC<ProductFullPageGalleryProps> = ({
  alt,
  currentImageIndex,
  images,
  isVisible,
  onCloseClick,
}: ProductFullPageGalleryProps) => {
  const theme = useTheme()
  const [currentSlide, setCurrentSlide] = useState<number | undefined>(currentImageIndex)
  const [areImagesLoaded, setAreImagesLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (isVisible && currentImageIndex !== undefined) {
      setCurrentSlide(currentImageIndex)
    }
  }, [currentImageIndex, isVisible])

  /**
   * This ensures that the images are only loaded once
   * when the the gallery becomes visible.
   */
  useEffect(() => {
    if (isVisible && !areImagesLoaded) {
      setAreImagesLoaded(true)
    }
  }, [isVisible, areImagesLoaded])

  return (
    <ProductFullPageGalleryContainer isVisible={isVisible}>
      <ProductFullPageGalleryCloseIcon onClick={() => onCloseClick && onCloseClick()}>
        <SVGIcon library="close" name="close" color={theme.palette.primary.main} />
      </ProductFullPageGalleryCloseIcon>

      {areImagesLoaded && !!images?.length && (
        <>
          <ProductFullPageGalleryImagesSlider
            currentSlide={currentSlide}
            onSlideChange={slideNumber => setCurrentSlide(slideNumber || 0)}
            images={images}
            sliderImageProps={{
              alt,
              srcsetmap: {
                300: '320w',
                400: '640w',
                600: '960w',
                700: '1280w',
                900: '1920w',
              },
              width: 600,
              usage: 'PDP',
            }}
            sliderProps={{
              loop: false,
            }}
          />
          <ProductFullPageGalleryThumbsSlider
            currentSlide={currentSlide}
            onImageClick={index => {
              setCurrentSlide(index)
            }}
            images={images}
            sliderImageProps={{
              alt,
              srcsetmap: {
                200: '320w',
                300: '640w',
              },
              width: 100 / images.length,
              usage: 'PDP',
            }}
            sliderProps={{
              slidesPerView: 'auto',
              spaceBetween: 16,
              pagination: {
                type: 'progressbar',
              },
              loop: false,
              freeMode: true,
              navigation: false,
              modules: [Pagination],
            }}
          />
        </>
      )}
    </ProductFullPageGalleryContainer>
  )
}

export default ProductFullPageGallery
