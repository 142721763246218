import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import { SwiperSlide } from 'swiper/react'

export const StyledSwiperSlide = styled(SwiperSlide, {
  name: 'ProductColorSlider',
  slot: 'SwiperSlide',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 120,
  },
}))

export const ProductColorSelectorContainer = styled('div', {
  name: 'ProductColorSlider',
  slot: 'Container',
})(() => ({}))

export const StyledSelectedColor = styled(Typography, {
  name: 'ProductColorSlider',
  slot: 'SelectedColor',
})(() => ({}))

export const StyledSelectedColorLabel = styled('span', {
  name: 'ProductColorSlider',
  slot: 'SelectedColorLabel',
})(({ theme }) => ({
  display: 'block',
  fontSize: 14,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  lineHeight: 1.5,
  paddingBottom: theme.spacing(6),
}))

export const StyledColorThumbButton = styled('button', {
  name: 'ProductColorSlider',
  slot: 'ThumbButton',
})<{ selected: boolean }>(({ selected, theme }) => ({
  display: 'flex',
  width: 109,
  [theme.breakpoints.up('sm')]: {
    width: 120,
  },
  height: 80,
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  boxShadow: 'none',

  '::after': {
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: selected ? `1px solid ${theme.palette.custom.blue}` : 'none',
    boxSizing: 'border-box',
  },
  ':hover': {
    '::after': {
      border: `1px solid ${selected ? theme.palette.custom.blue : theme.palette.custom.light.grey}`,
    },
  },
}))

export const SwiperWrapper = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SliderWrapper',
})(({ theme }) => ({
  width: '325px', // bleach...
  [theme.breakpoints.up('sm')]: {
    width: '360px',
  },
}))

export const ArrowDiv = styled('div', {
  name: 'ProductColorSlider',
  slot: 'ArrowDiv',
})(({ theme }) => ({
  svg: {
    path: {
      fill: theme.palette.text.dark.primary,
    },
  },
  ':hover': {
    svg: {
      path: {
        fill: theme.palette.custom.light1.grey,
      },
    },
  },
}))

/*
 * Actually, our Material UI version doesn't support "number" as spacing for Grid
 * So this is a little hacky, we should use spacing instead.
 * */
export const StyledColorsContainer = styled('div', {
  name: 'ProductColorSlider',
  slot: 'ColorsContainer',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 1,
  marginLeft: 1,
  '.swiper-button-disabled': {
    visibility: 'hidden',
  },
}))

export const StyledSelectedFrame = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SelectedFrame',
  shouldForwardProp: prop => prop !== 'type',
})<{ type?: any }>(({ theme, type }) => ({
  fontSize: 12,
  color: theme.palette.text.dark.primary,
  paddingTop: type === 'Sun' ? theme.spacing(6) : theme.spacing(),
  '& > span': {
    fontWeight: 'bold',
  },
}))

export const StyledSwiperContainer = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SwiperContainer',
})(() => ({
  width: 'inherit',
}))

export const ProductColorInfoText = styled('p', {
  name: 'ProductColorSlider',
  slot: 'ColorInfoText',
  shouldForwardProp: prop => prop !== 'isLensRelatedProduct',
})<{ isLensRelatedProduct?: boolean }>(({ theme, isLensRelatedProduct }) => ({
  margin: theme.spacing(isLensRelatedProduct ? 2 : 6, 0, 0),
  fontSize: 12,
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
}))

export const ProductColorSelectorProp = styled('span', {
  name: 'ProductColorSelector',
  slot: 'Property Label',
})(() => ({
  fontWeight: 600,
}))
