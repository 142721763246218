import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { useSite } from '@foundation/hooks/useSite'
import { IProduct } from '@typesApp/product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import Head from 'next/head'

export interface ProductDetailsHeadProps {
  product: IProduct
}

export const ProductDetailsHead: React.FC<ProductDetailsHeadProps> = ({ product }) => {
  const customerSegments = useCustomerSegmentsUtil()
  const productPrice = determineAlgoliaPrice(product.x_price, customerSegments)

  const {mySite} = useSite()
  const isWordLiftEnabled = mySite.xStoreCfg.WORD_LIFT_SCRIPT

  if (isWordLiftEnabled) return null

  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: product.productAttributes['MODEL_NAME'],
          image: [product?.attachments?.map(el => el.image)],
          description: product?.longDescription,
          sku: product?.partNumber,
          brand: {
            '@type': 'Brand',
            name: product.productAttributes['BRAND'],
          },
          offers: {
            '@type': 'AggregateOffer',
            lowPrice: productPrice?.listPrice,
            highPrice: productPrice?.offerPrice,
            priceCurrency: productPrice?.currency,
          },
        })}
      </script>
    </Head>
  )
}
