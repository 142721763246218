import React from 'react'
import { useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { StyledTypography } from '../../../../components/UI'
import { ImageContainer } from './SizeGuide.style'
import config from '@configs/index'

const fitGuide = config.publicUrl + 'images/common/fitGuide.png'

export const FitGuide = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
      <StyledTypography
        isUppercased
        marginBottom={4}
        fontSize={20}
        fontWeight={'600'}
        color={theme.palette.text.dark.primary}
      >
        {t('SizeFitGuide.fit.title')}
      </StyledTypography>

      <StyledTypography
        marginBottom={4}
        color={theme.palette.text.dark.primary}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.fit.description1'),
        }}
      />
      <ImageContainer>
        <img src={fitGuide} />
      </ImageContainer>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.text.dark.primary}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.fit.description2'),
        }}
      />
    </div>
  )
})
