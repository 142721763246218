import React, { useCallback, useState } from 'react'
import { useConnector } from 'react-instantsearch-hooks-web'
import connectRange, { type RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange'
import debounce from 'lodash/debounce'

import type { RangeConnectorParams, RangeWidgetDescription } from 'instantsearch.js/es/connectors/range/connectRange'
import { RangeInput, RangeInputWrapper, RangeTextInput } from '../PlpFilter.style'

export function useRangeSlider(props?: RangeConnectorParams) {
  return useConnector<RangeConnectorParams, RangeWidgetDescription>(connectRange, props)
}

export interface RangeSliderProps extends RangeConnectorParams {
  attribute: string
  selectedRange: [number, number]
  onRangeSliderChange: (newValues: RangeSliderProps['selectedRange']) => void
}

export function RangeSlider({ attribute, selectedRange, onRangeSliderChange }: RangeSliderProps) {
  const { refine, start, range } = useRangeSlider({
    attribute,
  })

  const getDefaultValues = (
    range: RangeRenderState['range'],
    selectedRange: RangeSliderProps['selectedRange'],
    start: RangeRenderState['start']
  ): RangeSliderProps['selectedRange'] => {
    if (selectedRange) {
      if (selectedRange?.[0] === selectedRange?.[1] && selectedRange?.[0] === 0) {
        return [range.min ?? 0, range.max ?? 0]
      }
      return selectedRange
    }

    if (start[0] !== undefined && start[1] !== undefined && Number.isFinite(start[0]) && Number.isFinite(start[1])) {
      return [start[0], start[1]]
    }

    return [range.min ?? 0, range.max ?? 0]
  }

  const [values, setValues] = useState<RangeSliderProps['selectedRange']>(getDefaultValues(range, selectedRange, start))

  // useCallback doesn't like function  returned function from another function.
  // Eslint won't be able to figure out what are the dependancies of the function, so it will show this warning.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateResultSet = useCallback(
    debounce(values => {
      refine(values)
      onRangeSliderChange(values)
    }, 600),
    []
  )

  const handleRangeSliderChange = (newValues: RangeSliderProps['selectedRange']) => {
    setValues(newValues)
    updateResultSet(newValues)
  }

  const onRangeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newValues: RangeSliderProps['selectedRange'] = [...values]
    newValues[name === 'min' ? 0 : 1] = Number(value)
    handleRangeSliderChange(newValues)
  }

  return (
    <>
      <RangeInputWrapper>
        <RangeInput
          min={range.min}
          max={range.max}
          value={values}
          valueLabelDisplay="auto"
          onChange={(event, newValues) => {
            handleRangeSliderChange(newValues as RangeSliderProps['selectedRange'])
          }}
        />
      </RangeInputWrapper>
      <RangeInputWrapper>
        <RangeTextInput
          value={values[0]}
          min={range.min}
          max={values[1] - 1}
          type="number"
          name="min"
          onChange={onRangeInputChange}
        />
        <RangeTextInput
          value={values[1]}
          min={values[0] + 1}
          max={range.max}
          type="number"
          name="max"
          onChange={onRangeInputChange}
        />
      </RangeInputWrapper>
    </>
  )
}
