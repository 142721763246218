import React from 'react'
import { useTranslation } from 'next-i18next'
import { ProductType } from '@typesApp/product'
import { StyledShippingTimesBanner, StyledShippingTimesContentItem } from './ShippingTimesBanner.style'

export interface ShippingTimesBannerProps {
  productType: ProductType
}

export const ShippingTimesBanner: React.FC<ShippingTimesBannerProps> = ({ productType }) => {
  const { t } = useTranslation()
  const content: string[] = t(`ShippingTimesBanner.Content-${productType}`, { returnObjects: true })

  return (
    <StyledShippingTimesBanner>
      <div>
        {content?.map((line, index) => (
          <StyledShippingTimesContentItem key={index} dangerouslySetInnerHTML={{ __html: line }} />
        ))}
      </div>
    </StyledShippingTimesBanner>
  )
}
