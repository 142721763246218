import { IconButton } from '../../../../components/UI'
import { ProductFullPageGalleryProps } from '../ProductFullPageGallery'
import ProductImage from '../../../../components/ProductImage/ProductImage'
import { Swiper } from 'swiper/react'
import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV5 } from '@constants/ui'

const ProductImageSlider = styled(Swiper, {
  name: 'PdpDetails',
  slot: 'ImageSlider',
})(({ theme }) => ({
  '.swiper-slide img': {
    objectFit: 'contain',
    height: '50%',
    backgroundColor: theme.palette.custom.white, // white background because the image is transparent
  },
}))

const SliderThumbNailsWrapper = styled(Swiper, {
  name: 'PdpDetails',
  slot: 'SliderThumbNailsWrapper',
  shouldForwardProp: prop => prop !== 'isGalleryMode',
})<{ isGalleryMode?: boolean }>(({ isGalleryMode }) => ({
  marginTop: isGalleryMode ? 0 : 16,
  marginBottom: 8,
  height: '345px',
}))

const WrapperThumbnails = styled('div', {
  name: 'PdpDetails',
  slot: 'WrapperThumbnail',
})(({}) => ({
  display: 'block',
}))

const ThumbnailButtonContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: `calc(${theme.spacing(20)} - 2px )`,
  height: `calc(${theme.spacing(14)} - 2px )`,
}))

const SliderContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(() => ({
  width: '100%',
  position: 'relative',
}))

const SliderThumbNailContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'selected',
  name: 'PdpDetails',
  slot: 'SliderThumbNailContainer',
})<{
  selected?: boolean
}>(({ selected, theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  width: `calc(${theme.spacing(20)} - 2px )`,
  height: `calc(${theme.spacing(14)} - 2px )`,
  border: `1px solid  ${selected ? theme.palette.custom.boulder : theme.palette.custom.white}`,
  '&:hover': {
    border: `1px solid  ${theme.palette.custom.alto}`,
  },

  'span.lazy-load-image-background': {
    width: '100%',
    height: '56px',
    lineHeight: '0',
  },
}))

const ThumbNailBrandContainer = styled(SliderThumbNailContainer, {
  name: 'PdpDetails',
  slot: 'SliderContainer',
})(() => ({
  cursor: 'auto',
  border: '1px solid transparent',

  '&:hover': {
    borderColor: 'transparent',
  },
  div: {
    height: '100%',
  },
  img: {
    width: '100%',
  },
}))

const SliderThumbNail = styled(ProductImage, {
  name: 'PdpDetails',
  slot: 'SliderThumbNail',
})(() => ({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  position: 'relative',

  video: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

const SliderNavigationContainer = styled('div', {
  name: 'PdpDetails',
  slot: 'SliderNavigationContainer',
})(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  zIndex: '2',
  top: `calc(50% - ${theme.spacing(4)})`,
}))

const SliderNavigationButton = styled(IconButton, {
  name: 'PdpDetails',
  slot: 'SliderNavigationButton',
  shouldForwardProp: prop => prop !== 'positionStart' && prop !== 'positionEnd',
})<{ positionStart?: boolean; positionEnd?: boolean }>(({ positionStart, positionEnd }) => ({
  padding: '0',
  visibility: positionStart || positionEnd ? 'hidden' : 'visible',
}))

// FULL PAGE GALLERY SLIDER
const ProductFullPageGalleryContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isVisible' && prop === 'children',
  name: 'PdpDetails',
  slot: 'ProductFullPageGalleryContainer',
})<ProductFullPageGalleryProps>(({ isVisible, theme }) => ({
  position: 'fixed',
  display: isVisible ? 'flex' : 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.white,
  width: '100%',
  height: '100%',
  zIndex: Z_INDEX_LV5,
  left: '0',
  top: '0',
}))

const ProductFullPageGalleryCloseIcon = styled(IconButton, {
  name: 'ProductFullPageGallery',
  slot: 'CloseIcon',
})(({ theme }) => ({
  borderRadius: '0',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: theme.spacing(4),
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '2',
  svg: {
    width: theme.spacing(6),
  },
}))

export {
  SliderThumbNailsWrapper,
  SliderContainer,
  SliderThumbNailContainer,
  SliderThumbNail,
  SliderNavigationContainer,
  SliderNavigationButton,
  ProductFullPageGalleryContainer,
  ProductImageSlider,
  ProductFullPageGalleryCloseIcon,
  ThumbNailBrandContainer,
  WrapperThumbnails,
  ThumbnailButtonContainer,
}
