import { APP_MAIN_CONTAINER_ID } from '@constants/ui'
import { useEffect, useState } from 'react'

const useStickyOnScroll = (elementId = 'X_X_Tiles') => {
  const [isSticky, setIsSticky] = useState(false)

  const toggleHeader = e => {
    const scrollPosition = e?.target?.scrollTop
    const element = document.getElementById(elementId)
    const elementPosition = element?.offsetTop || 0
    setIsSticky(scrollPosition > elementPosition)
  }

  useEffect(() => {
    const element = document.getElementById(APP_MAIN_CONTAINER_ID)
    element?.addEventListener('scroll', toggleHeader)
    return () => {
      element?.removeEventListener('scroll', toggleHeader)
    }
  }, [])

  return isSticky
}

export default useStickyOnScroll
