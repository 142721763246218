import React, { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import styles from './styles/ProductUnsuitableMessage.module.scss'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface IUnsuitableMessage {
  label: string
}

const ProductUnsuitableMessage: FC<IUnsuitableMessage> = props => {
  const { label } = props
  const theme = useTheme()
  return (
    <p className={styles.container}>
      <SVGIcon library="global" name="unsuitable-driving" size={24} color={theme.palette.background.dark.primary} />
      {label}
    </p>
  )
}

export default ProductUnsuitableMessage
