import ProductImage, { ProductImageProps } from '../../../../components/ProductImage/ProductImage'
import {
  ProductImageSlider,
  SliderContainer,
  SliderNavigationButton,
  SliderNavigationContainer,
} from './ProductImagesSlider.style'
import React, { FC, useEffect, useRef } from 'react'
import { SwiperProps, SwiperSlide } from 'swiper/react'

import { Attachment } from '@typesApp/product'
import { EffectFade } from 'swiper'
// TYPES
import { Swiper as SwiperClass } from 'swiper/types'
import { useTheme } from '@mui/material/styles'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
/**
 * @param { Attachment[] } images slider images
 * @param { ProductImageProps } sliderImageProps slider images props
 * @param { string } mainCarouselWidth width in pixel of slider images and slider
 * @param { function } onImageClick callback when clicking the image
 * @param { function } onPrevClick callback when clicking the prev slide button
 * @param { function } onNextClick callback when clicking the next slide button
 * @param { function } onSlideChange callback when slide changes
 * @param { SwiperProps} sliderProps you can pass props to the main slider using this prop
 * @param { number } currentSlide you can force the slide index to the slider
 * @param { boolean } shownavigation show navigation buttons
 * visit https://swiperjs.com/react for more infos
 */

export interface ProductImagesSliderProps {
  sliderProps?: SwiperProps
  sliderImageProps: ProductImageProps
  images?: Attachment[]
  mainCarouselWidth?: number
  onImageClick?: (index: number) => void
  onPrevClick?: () => void
  onNextClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  currentSlide?: number
  pdpdataloading?: boolean
  shownavigation?: boolean
  backgroundColor?: string
}

const ProductImagesSlider: FC<ProductImagesSliderProps> = ({
  images,
  currentSlide = 0,
  sliderProps,
  onImageClick,
  onSlideChange,
  pdpdataloading,
  shownavigation,
  sliderImageProps,
  ...rest
}: ProductImagesSliderProps) => {
  const theme = useTheme()
  const swiperRef = useRef<SwiperClass | null>(null)

  useEffect(() => {
    swiperRef.current?.slideToLoop(currentSlide)
  }, [currentSlide])

  return (
    <>
      {images && images?.length > 0 && !pdpdataloading && (
        <>
          <SliderContainer {...rest}>
            {shownavigation && (
              <SliderNavigationContainer>
                <SliderNavigationButton
                  aria-label="SliderNavigationButtonPrevious"
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SVGIcon library="arrow" name="arrow-left" color={theme.palette.custom.boulder} />
                </SliderNavigationButton>
                <SliderNavigationButton
                  aria-label="SliderNavigationButtonNext"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SVGIcon library="arrow" name="arrow-right" color={theme.palette.custom.boulder} />
                </SliderNavigationButton>
              </SliderNavigationContainer>
            )}
            <ProductImageSlider
              loop={true}
              slidesPerView={1}
              onSwiper={swiper => (swiperRef.current = swiper)}
              onSlideChange={() => onSlideChange && onSlideChange(swiperRef.current?.realIndex)}
              modules={[EffectFade]}
              effect="fade"
              autoHeight={true}
              {...sliderProps}
            >
              {images?.map((_, index) => (
                <SwiperSlide key={index} className={currentSlide === index ? 'selected' : ''}>
                  <ProductImage
                    onClick={() => onImageClick && onImageClick(index)}
                    srcsetmap={sliderImageProps?.srcsetmap}
                    width={sliderImageProps?.width}
                    usage={sliderImageProps?.usage}
                    attachments={[images[index]]}
                    backgroundColor={theme.palette.background.light.primary}
                    active={currentSlide === index} // For video carousel items only
                    autoPlay={true} // For video carousel items only
                    preventlazyLoad={index === 0} // Don't lazyload the first image in the carousel
                    loadOnActive={true}
                    preload={index === 0}
                    {...sliderImageProps}
                  />
                </SwiperSlide>
              ))}
            </ProductImageSlider>
          </SliderContainer>
        </>
      )}
    </>
  )
}

export default ProductImagesSlider
