import styled from '@mui/material/styles/styled'

export const StyledPagionationLayoutContainer = styled('div', {
  name: 'PaginationLayout',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(),
  margin: `0 ${theme.spacing(1)}`,

  div: {
    marginTop: `-${theme.spacing(0.5)}`,
    display: 'flex',
    flexDirection: 'row',
  },

  svg: {
    height: 16,
    cursor: 'pointer',
  },
}))

export const StyledPaginationContainer = styled('div', {
  name: 'Pagination',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  height: theme.spacing(8),
  width: theme.spacing(8),
  borderRadius: '50%',
  border: 'none',
  background: 'none',
  position: 'relative',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  margin: `0 ${theme.spacing(1)}`,
  fontSize: theme.spacing(4),
  lineHeight: theme.spacing(6),
  fontWeight: '400',

  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },

  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.custom.blue,
    backgroundColor: theme.palette.custom.light.blue,
  },

  '&.selected': {
    a: {
      color: theme.palette.custom.light.blue,
      fontWeight: '700',
      fontSize: theme.spacing(4),

      '&:hover': {
        color: theme.palette.custom.light.blue,
      },
    },
  },

  '&.selected::after': {
    content: '""',

    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    borderRadius: '50%',
    backgroundColor: theme.palette.custom.blue,
  },

  a: {
    color: theme.palette.custom.grey,
    textDecoration: 'none',
    fontSize: theme.spacing(4),

    '&:after': {
      background: 'none',
    },
  },
}))
