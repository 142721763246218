import { useSelector } from 'react-redux'
import {
  ProductColorSelectorContainer,
  StyledColorsContainer,
  StyledSelectedColor,
  StyledSelectedColorLabel,
  SwiperWrapper,
  ProductColorInfoText,
  ProductColorSelectorProp,
  StyledColorThumbButton,
  StyledSwiperSlide,
} from './ProductColorSelector.style'
import { ProductRightColumnProps, IProduct } from '@typesApp/product'
import React, { FC, useState, useEffect } from 'react'
import { Swiper } from 'swiper/react'
import { getModelName, getSoldOut } from '../../../../utils/productAttributes'

import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import { Navigation } from 'swiper'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_MAP } from '../../../../constants/product'
import ProductColorItem from '../ProductColorItem'
import WithSpinningLoader from '../../../../components/with-loading/WithLoading'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import { useTranslation } from 'next-i18next'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { COLON_CHARACTER } from '../../../../constants/ui'
import { moCoOrderedIdsFromPlp } from '@features/product/selector'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface ProductColorSelectorProps {
  isLoading: boolean
  cluster: IProduct['cluster']
  currentProductItem?: IProduct | null
  soldOutStatus: ProductRightColumnProps['soldOutStatus']
  selectedPartNumber: string
  onClick?: (p: IProduct) => void
}

const ProductColorSelector: FC<ProductColorSelectorProps> = ({
  isLoading,
  cluster,
  selectedPartNumber,
  currentProductItem,
  onClick,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isLgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'))
  const moCoOrderedIds = useSelector(moCoOrderedIdsFromPlp)

  const frontColor = !!currentProductItem ? currentProductItem.productAttributes['FRONT_COLOR'] : null
  const productType = !!currentProductItem && currentProductItem.productAttributes['PRODUCT_TYPE']

  const lensColor = !!currentProductItem && currentProductItem.productAttributes['LENS_COLOR']

  const isOpticalPage = productType === 'Optical'

  let colorLabel = 'ProductColorSelector.Labels.Colour'
  if (
    productType &&
    ([PRODUCT_TYPES_MAP.frames, PRODUCT_TYPES_MAP.optical, PRODUCT_TYPES_MAP.sun] as string[]).indexOf(
      productType.toLowerCase()
    ) !== -1
  ) {
    colorLabel = 'ProductColorSelector.Labels.Frame'
  }
  const getClusterSorted = () => {
    if (!cluster) return []

    const newClusterSort = sortBy(cluster, ({ items }) => {
      return items?.filter(({ partNumber }) => {
        return partNumber !== selectedPartNumber
      })
    })
    return newClusterSort
  }

  const [newCluster, setNewCluster] = useState(getClusterSorted())
  useEffect(() => {
    // setting the selected items always on the first place
    if (!newCluster || (isEmpty(newCluster) && cluster)) {
      const newClusterSort = getClusterSorted()
      let newOrderedCluster: IProduct[] = Array(moCoOrderedIds.length)

      if (moCoOrderedIds.length > 0) {
        newClusterSort.forEach(p => {
          const position = moCoOrderedIds.indexOf(p.uniqueID as string)
          if (position !== -1) {
            newOrderedCluster[position] = p
          } else {
            newOrderedCluster.push(p)
          }
        })
      } else {
        newOrderedCluster = newClusterSort
      }
      setNewCluster(newOrderedCluster)
    }
  }, [cluster, newCluster])

  const controlPartNumber = p => {
    const productSelected = !!p.items
      ? p.items.find((item: IProduct) => item.partNumber === selectedPartNumber)
      : p.partNumber === selectedPartNumber
    if (productSelected) return productSelected.partNumber
  }

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  const [activeSlider, setActiveSlider] = useState<number>(0)

  if (!cluster) return null

  const sortedNewCluster: any = []

  newCluster
    ?.filter(p => getSoldOut(p.items?.[0] || p) !== PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    .map(p => sortedNewCluster.push(p))

  newCluster
    ?.filter(p => getSoldOut(p.items?.[0] || p) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    .map(p => sortedNewCluster.push(p))

  const isSunProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Sun'
  }

  const isOpticalProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Optical'
  }

  const isFramesProduct = (product: IProduct | null | undefined) => {
    if (!product) return false
    const productType = product.productAttributes['PRODUCT_TYPE']
    return productType === 'Frames'
  }

  const isLensRelatedProduct =
    isSunProduct(currentProductItem) || isOpticalProduct(currentProductItem) || isFramesProduct(currentProductItem)

  return (
    <ProductColorSelectorContainer>
      {cluster?.length >= 0 && (
        <>
          <StyledSelectedColor>
            <StyledSelectedColorLabel>
              <WithSpinningLoader isLoading={isLoading}>
                {cluster?.filter(x => x.displayable).length}{' '}
                {cluster?.length && cluster?.length > 1
                  ? t('ProductColorSelector.Labels.ColoursAvailable')
                  : t('ProductColorSelector.Labels.ColourAvailable')}
                {COLON_CHARACTER}
              </WithSpinningLoader>
            </StyledSelectedColorLabel>
          </StyledSelectedColor>
          {isLoading ? (
            <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
              <Skeleton style={{ height: 80, borderRadius: 0 }} />
            </SkeletonTheme>
          ) : (
            <StyledColorsContainer>
              <div ref={s => setPrevEl(s)}>
                {activeSlider !== 0 && (
                  <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} />
                )}
              </div>
              <SwiperWrapper>
                <WithSpinningLoader isLoading={isLoading} skeletonCount={isLgBreakpoint ? 5 : 4}>
                  <Swiper
                    navigation={{ prevEl, nextEl }}
                    modules={[Navigation]}
                    slidesPerView={3}
                    slidesPerGroup={3}
                    spaceBetween={0}
                    simulateTouch={false}
                    onSlideChange={swiper => setActiveSlider(swiper.activeIndex)}
                  >
                    {newCluster?.map((p, index) => (
                      <React.Fragment key={p.uniqueID}>
                        {p?.displayable ? (
                          <StyledSwiperSlide key={p.uniqueID || index}>
                            <StyledColorThumbButton
                              data-element-id="X_X_Prod_ColorThumbs"
                              data-description={`${getModelName(p)}_${
                                controlPartNumber(p) || !!p.items
                                  ? p?.items?.find(item => !!item)?.partNumber
                                  : p.partNumber
                              }`}
                              onClick={() => onClick?.(p)}
                              selected={controlPartNumber(p) === selectedPartNumber}
                            >
                              <ProductColorItem product={p?.items?.find(item => !!item) || p} />
                            </StyledColorThumbButton>
                          </StyledSwiperSlide>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </Swiper>
                </WithSpinningLoader>
              </SwiperWrapper>
              <div ref={s => setNextEl(s)}>
                <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
              </div>
            </StyledColorsContainer>
          )}
        </>
      )}

      {cluster && cluster?.length >= 0 && !!frontColor && (
        <ProductColorInfoText>
          <WithSpinningLoader isLoading={isLoading}>
            <ProductColorSelectorProp>
              {t(colorLabel)}
              {COLON_CHARACTER}
            </ProductColorSelectorProp>{' '}
            {frontColor}
          </WithSpinningLoader>
        </ProductColorInfoText>
      )}

      {cluster && cluster?.length && isLensRelatedProduct && !isOpticalPage ? (
        <ProductColorInfoText isLensRelatedProduct={isLensRelatedProduct}>
          <WithSpinningLoader isLoading={isLoading}>
            <ProductColorSelectorProp>
              {t('ProductColorSelector.Labels.Lenses')}
              {COLON_CHARACTER}
            </ProductColorSelectorProp>{' '}
            {lensColor}
          </WithSpinningLoader>
        </ProductColorInfoText>
      ) : null}
    </ProductColorSelectorContainer>
  )
}

export default ProductColorSelector
