import React from 'react'
import { SizeSwitcherContainer, SizeSwitcherOption, SizeSwitcherOptionSelected } from './SizeSwitcher.style'
import { capitalize } from '@utils/helpers'
import { uniqBy } from 'lodash'
import { SizeOption } from '@typesApp/product'

type Props = {
  options: SizeOption[]
  currentSize: string
}

const SizeSwitcher = ({ options, currentSize }: Props) => {
  const getPath = href => `${href}`

  const allOptions = uniqBy(options.concat({ size: currentSize, label: options[0].label }), e => e.size).filter(
    option => !!option.href
  )

  return (
    <SizeSwitcherContainer>
      {allOptions
        ?.sort(function (a, b) {
          return parseInt(a.size) - parseInt(b.size)
        })
        .map(option =>
          currentSize === option.size ? (
            <React.Fragment key={option.size}>
              <SizeSwitcherOptionSelected>{`${currentSize} ${capitalize(option.label)}`}</SizeSwitcherOptionSelected>
              <SizeSwitcherOption href={getPath(option.href)}>{`${option.size} ${capitalize(
                option.label
              )}`}</SizeSwitcherOption>
            </React.Fragment>
          ) : (
            <React.Fragment key={option.size}>
              <SizeSwitcherOption href={getPath(option.href)}>{`${option.size} ${capitalize(
                option.label
              )}`}</SizeSwitcherOption>
              <SizeSwitcherOptionSelected>{`${currentSize} ${capitalize(option.label)}`}</SizeSwitcherOptionSelected>
            </React.Fragment>
          )
        )}
    </SizeSwitcherContainer>
  )
}

export default SizeSwitcher
