import useTheme from '@mui/material/styles/useTheme'

import React from 'react'
import styled from '@mui/material/styles/styled'
import BrandIcon from '@components/BrandIcon'
import { StyledTopPageTitle } from '@components/BrandIcon/BrandIcon.style'

const StyledTopPageContainer = styled('div', {
  name: 'TopSEOBlock',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(-2),
  padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)}`,
  color: theme.palette.text.light.primary,
  backgroundColor: theme.palette.text.dark.primary,
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(-4.12),
    padding: `${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(6)} ${theme.spacing(8)}`,
  },

  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(36)} ${theme.spacing(6)} ${theme.spacing(36)}`,
  },
}))

const StyledTopPageContent = styled('div', {
  name: 'TopSEOBlock',
  slot: 'Content',
})(() => ({
  textAlign: 'center',
  fontSize: 16,
  width: '95%',
}))

const WrapperBrandIconTopSeo = styled('div', {
  name: 'BrandIcon',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(8),

  img: {
    width: 145,
  },
}))

const TopSEOBlock = ({
  isEnabled,
  title,
  description,
  brandName,
  currentOffset,
}: {
  isEnabled: boolean
  title: string
  description: string
  brandName: string
  currentOffset: number
}) => {
  const theme = useTheme()

  return isEnabled && !currentOffset ? (
    <StyledTopPageContainer theme={theme} className="plp__seo-text-container">
      {brandName ? (
        <WrapperBrandIconTopSeo>
          <BrandIcon name={brandName} />
        </WrapperBrandIconTopSeo>
      ) : (
        <StyledTopPageTitle>{title}</StyledTopPageTitle>
      )}
      <StyledTopPageContent theme={theme}>
        <div className="seo-content" dangerouslySetInnerHTML={{ __html: description || '' }} />
      </StyledTopPageContent>
    </StyledTopPageContainer>
  ) : null
}

export default TopSEOBlock
