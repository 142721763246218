import React from 'react'
import range from 'lodash/range'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import type { PaginationRenderState } from 'instantsearch.js/es/connectors/pagination/connectPagination'
import { StyledAnchor } from '@components/UI/Anchor/Anchor'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'
import theme from '@themes/index'
import { usePlpDispatch } from '@utils/Plp/PlpContext'
import { formatPathWithBase } from '@utils/url'
import { StyledPaginationContainer, StyledPagionationLayoutContainer } from './PaginationLayout.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface Props {
  paginationState: PaginationRenderState
}

export interface PaginationContainerProps {
  isSelected?: boolean
  onClick: () => void
  page: number
  pageLabel?: string | React.ReactElement
}

const groupPagesLimit = 6
const edgesPagesLimit = 5
const middlePagesLimit = 5

const isPageInFirstGroup = (page: number) => page >= 1 && page <= edgesPagesLimit
const isPageInLastGroup = (page: number, totalPages: number) =>
  page <= totalPages && page > totalPages - edgesPagesLimit

const PaginationContainer: React.FC<PaginationContainerProps> = ({ isSelected = false, onClick, page, pageLabel }) => {
  const { origin = '', pathname = '' } = typeof window !== 'undefined' && window.location ? window.location : {}
  const url = formatPathWithBase(pathname, origin)

  return (
    <StyledPaginationContainer className={isSelected ? 'selected' : ''} onClick={onClick}>
      <StyledAnchor
        aria-label={`Page ${page}`}
        external={true}
        href={`${url}?page=${page}`}
        onClick={e => e.preventDefault()}
        variant="noAnimation"
      >
        {pageLabel ?? page}
      </StyledAnchor>
    </StyledPaginationContainer>
  )
}

const PaginationLayout: React.FC<Props> = ({ paginationState }) => {
  const plpDispatch = usePlpDispatch()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()
  const { nbPages: totalPages, refine, currentRefinement } = paginationState
  const currentPage = currentRefinement + 1
  const pagesArray = range(0, totalPages)

  const handleChange = (value: number) => () => {
    const appMainContainerElement = document.getElementById(APP_MAIN_CONTAINER_ID)
    appMainContainerElement?.scrollTo({ top: 0, behavior: 'smooth' })
    plpDispatch({ type: 'SET_FIRST_LOAD', payload: true })
    refine(value - 1)
    const params = new URLSearchParams(searchParams)
    params.set('page', value.toLocaleString())
    replace(`${pathname}?${params.toString()}`, { scroll: false })
  }

  const shouldFitInOneGroup = totalPages <= groupPagesLimit
  const isCurrentInFirstGroup = isPageInFirstGroup(currentPage) && isPageInFirstGroup(currentPage + 2)
  const isCurrentInLastGroup =
    isPageInLastGroup(currentPage, totalPages) && isPageInLastGroup(currentPage - 2, totalPages)
  const isCurrentInMiddleGroup = !isCurrentInFirstGroup && !isCurrentInLastGroup

  const pagesGroupStart = currentPage - 2
  const pagesGroupEnd = pagesGroupStart + middlePagesLimit

  const renderPagination = () => {
    if (shouldFitInOneGroup) {
      return (
        <>
          {pagesArray.map(pageIndex => {
            const page = pageIndex + 1
            return (
              <PaginationContainer
                key={`button_pagination_${page}`}
                isSelected={currentPage === page}
                onClick={handleChange(page)}
                page={page}
              />
            )
          })}
        </>
      )
    } else if (isCurrentInFirstGroup) {
      return (
        <>
          {pagesArray.map(pageIndex => {
            const page = pageIndex + 1
            const isInFirstGroup = isPageInFirstGroup(page)

            if (isInFirstGroup) {
              return (
                <PaginationContainer
                  key={`button_pagination_${page}`}
                  isSelected={currentPage === page}
                  onClick={handleChange(page)}
                  page={page}
                />
              )
            }

            return null
          })}

          <PaginationContainer onClick={handleChange(edgesPagesLimit + 1)} page={edgesPagesLimit + 1} pageLabel="..." />
          <PaginationContainer onClick={handleChange(totalPages)} page={totalPages} />
        </>
      )
    } else if (isCurrentInLastGroup) {
      return (
        <>
          <PaginationContainer onClick={handleChange(1)} page={1} />
          <PaginationContainer
            onClick={handleChange(totalPages - edgesPagesLimit)}
            page={totalPages - edgesPagesLimit}
            pageLabel="..."
          />

          {pagesArray.map(pageIndex => {
            const page = pageIndex + 1
            const isInLastGroup = isPageInLastGroup(page, totalPages)

            if (isInLastGroup) {
              return (
                <PaginationContainer
                  key={`button_pagination_${page}`}
                  isSelected={currentPage === page}
                  onClick={handleChange(page)}
                  page={page}
                />
              )
            }

            return null
          })}
        </>
      )
    } else if (isCurrentInMiddleGroup) {
      return (
        <>
          <PaginationContainer onClick={handleChange(1)} page={1} />
          <PaginationContainer onClick={handleChange(pagesGroupStart - 1)} page={pagesGroupStart - 1} pageLabel="..." />

          {pagesArray.map(pageIndex => {
            const page = pageIndex + 1

            if (page >= pagesGroupStart && page < pagesGroupEnd) {
              return (
                <PaginationContainer
                  key={`button_pagination_${page}`}
                  isSelected={currentPage === page}
                  onClick={handleChange(page)}
                  page={page}
                />
              )
            }
            return null
          })}

          <PaginationContainer onClick={handleChange(pagesGroupEnd)} page={pagesGroupEnd} pageLabel="..." />
          <PaginationContainer onClick={handleChange(totalPages)} page={totalPages} />
        </>
      )
    }

    return null
  }

  return (
    <StyledPagionationLayoutContainer>
      {totalPages > groupPagesLimit && currentPage > 1 && (
        <PaginationContainer
          onClick={handleChange(currentPage - 1)}
          page={currentPage - 1}
          pageLabel={<SVGIcon library="arrow" name="arrow-left" color={theme.palette.color.black} />}
        />
      )}

      <div>{renderPagination()}</div>

      {totalPages > groupPagesLimit && currentPage < totalPages && (
        <PaginationContainer
          onClick={handleChange(currentPage + 1)}
          page={currentPage + 1}
          pageLabel={<SVGIcon library="arrow" name="arrow-right" color={theme.palette.color.black} />}
        />
      )}
    </StyledPagionationLayoutContainer>
  )
}

export default PaginationLayout
