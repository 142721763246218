import React from 'react'

import { useSite } from '../../../foundation/hooks/useSite'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { StyledSwitch } from '../../UI'

export const SizeAdvisorSwitcher: React.FC<{
  toggleHingesFacet: () => void
  enabled: boolean
}> = ({ toggleHingesFacet, enabled }) => {
  const { mySite } = useSite()
  const frameGeniusData = useFrameGenius()

  const isSizeAdvisorEnabled = mySite.xStoreCfg['FrameAdvisor.enabled'] || false

  if (!isSizeAdvisorEnabled) return null

  return (
    <StyledSwitch
      checked={enabled}
      disabled={!frameGeniusData?.analysisResults?.size}
      setChecked={toggleHingesFacet}
    />
  )
}
