import styled from '@mui/material/styles/styled'
import { Pill } from '../UI'

export const StyledProductTypePillFiltersWrapper = styled('div', {
  name: 'PillFilters',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '40px',
  background: theme.palette.custom.wildSand,

  [theme.breakpoints.down('md')]: {
    marginBottom: '24px',
  },
}))

export const StyledProductTypePillFilterButton = styled(Pill, {
  name: 'PillFilters',
  slot: 'Button',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  border: 'none',

  '>svg': {
    paddingLeft: '12px',
    paddingRight: '12px',
  },

  '&:hover': {
    border: 'none',
  },

  [theme.breakpoints.down('md')]: {
    width: 'auto',
    flex: '1 1 0px',
    '&:hover': {
      background: theme.palette.custom.cyprus,
      borderRadius: '40px',
      overflow: 'hidden',
    },
  },
}))
