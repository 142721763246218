import React from 'react'
import LoadingSkeleton from '../CmsComponents/CmsCommonMedia/LoadingSkeleton'
import { PlacementsSwitch } from '@components/Cms/PlacementsSwitch'
import { DEFAULT_ACTIVE_PLACEMENTS } from '@components/Cms/PlacementsSwitch/constants'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IProduct } from '../../../types/product'

const PlacementLayout: React.FC<{
  isPLP?: boolean
  loading: boolean
  pdpData?: IProduct
  placements: IPlacement[]
}> = ({ isPLP = false, loading, pdpData, placements }) => (
  <div className="placements-container">
    {loading ? (
      <LoadingSkeleton height={'100vh'} />
    ) : placements && placements.length === 0 ? null : (
      <PlacementsSwitch
        activePlacements={DEFAULT_ACTIVE_PLACEMENTS}
        isPLP={isPLP}
        pdpData={pdpData}
        placements={placements}
      />
    )}
  </div>
)

export default PlacementLayout
