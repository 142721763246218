import { ProductBrand, ProductName, ProductNameWrapper, ProductBrandWrapper } from './ProductNameCLSection.style'
import { getCLBrand, getModelName } from '../../../utils/productAttributes'

import React from 'react'
import { IProduct } from '../../../types/product'

export interface IProductDetailsList {
  currentProduct: IProduct
}

export const ProductNameCLSection = ({ currentProduct }: IProductDetailsList) => {
  return (
    <ProductNameWrapper>
      <ProductBrandWrapper>
        <ProductName>{getModelName(currentProduct)}</ProductName>
      </ProductBrandWrapper>

      <ProductBrand>{getCLBrand(currentProduct) || 'Brand name'}</ProductBrand>
    </ProductNameWrapper>
  )
}
