import { ALGOLIA_FILTERS } from '@foundation/constants/common'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useNavigateBack = () => {
  const router = useRouter()
  const [isBackNavigation, setIsBackNavigation] = useState(false)

  useEffect(() => {
    const handlePopState = () => {
      setIsBackNavigation(true)
    }

    if (typeof window !== 'undefined') window.addEventListener('popstate', handlePopState)

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (isBackNavigation && typeof window !== 'undefined') {
      const plpUrl = sessionStorage.getItem(ALGOLIA_FILTERS) ?? window.location.href

      router.replace(plpUrl)
    }
  }, [isBackNavigation, router])
}
