import React, { useEffect } from 'react'
import { useSearchBox } from 'react-instantsearch-hooks'
import { usePlpDispatch } from '@utils/Plp/PlpContext'

interface VirtualSearchBoxProps {
  algoliaQueryParam: string | undefined
}

export const VirtualSearchBox: React.FC<VirtualSearchBoxProps> = ({ algoliaQueryParam }) => {
  const plpDispatch = usePlpDispatch()
  const { refine } = useSearchBox()

  useEffect(() => {
    if (algoliaQueryParam) {
      plpDispatch({ type: 'SET_FIRST_LOAD', payload: true })
      refine(algoliaQueryParam)
    }
  }, [algoliaQueryParam, plpDispatch, refine])

  return null
}
