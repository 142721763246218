// import { useDispatch, useSelector } from 'react-redux'
import React from 'react'

const usePlpPlacements = ({ plpCommerce }) => {
  // const dispatch = useDispatch()

  const placementsPlpCommerce = plpCommerce?.commercePlacements || []
  const plpDescription = plpCommerce?.PLPDescription || {
    numFound: 0,
    result: [],
  }

  const preFooter = placementsPlpCommerce.find(placement => placement.name === 'footer_before_footer')

  React.useEffect(() => {
    // TODO: dispatch(setPreFooter(preFooter!))
  }, [preFooter])
  return {
    placementsPlpCommerce,
    plpDescription,
  }
}

export default usePlpPlacements
