import React from 'react'
import { StyledProductTypePillFilterButton, StyledProductTypePillFiltersWrapper } from './ProductTypePillFilters.style'
import useProductTypePillFilters from './useProductTypePillFilters'

const ProductTypePillFilters: React.FC = () => {
  const { items, selectedPT, getPTIcon, handleOnClickPT } = useProductTypePillFilters()

  if (!items) return null
  return (
    <StyledProductTypePillFiltersWrapper>
      {items.length > 1 &&
        items.map(pt => {
          return (
            <StyledProductTypePillFilterButton
              key={pt.label}
              startIcon={getPTIcon(pt.label)}
              variant={pt.label === selectedPT ? 'primary' : 'secondary'}
              onClick={() => handleOnClickPT(pt.label)}
            ></StyledProductTypePillFilterButton>
          )
        })}
    </StyledProductTypePillFiltersWrapper>
  )
}
export default ProductTypePillFilters
