import { Accordion, AccordionSummary } from '@components/UI/Accordion'

import styled from '@mui/material/styles/styled'

export const WrapperFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Wrapper',
})(({ theme }) => ({
  ul: {
    paddingLeft: theme.spacing(5), // see: https://abstractsrl.atlassian.net/browse/DCW-4527
    ['& ::marker']: {
      fontSize: 8,
    },
  },

  color: theme.palette.text.dark.primary,
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 24),
  },

  [theme.breakpoints.up(1280)]: {
    padding: theme.spacing(0, 44),
  },
}))

export const ContentFaqs = styled('div', {
  name: 'Faqs',
  slot: 'Content',
})(() => ({}))

export const StyledAccordion = styled(Accordion, {
  name: 'Faqs',
  slot: 'Accordion',
})(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderBottom: `1px solid ${theme.palette.background.dark.primary}`,
  },
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'Faqs',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  borderBottom: 'none',
  fontSize: 16,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  lineHeight: 1.5,
  minHeight: 0,
  margin: `${theme.spacing(4)} 0`,

  '& .MuiAccordionSummary-content': {
    padding: `${theme.spacing(1)} 0`,
  },
}))
