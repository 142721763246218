import { useDispatch, useSelector } from 'react-redux'
import { currentPageSeoDataSelector } from '@features/seo/selector'
import { useGetPdpCommerceQuery } from '@features/cms/query'
import find from 'lodash/find'
import { setPreFooter } from '@features/cms/action'
import React from 'react'
import { useRouter } from 'next/router'

const usePdpPlacements = (pdpData, breadcrumbs) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { previewDate, filterRulesLocaleOverride } = router.query as {
    previewDate: string
    filterRulesLocaleOverride: string
  }
  const seoData = useSelector(currentPageSeoDataSelector())

  const externalId = pdpData?.cluster && pdpData.cluster[0] ? pdpData.cluster[0].name : seoData?.tokenExternalValue

  let categoryIdentifiers = ''

  if (breadcrumbs?.length > 0) {
    const newBreadcrumbs = [{ categoryIdentifier: 'ROOT' }, ...breadcrumbs]
    categoryIdentifiers = newBreadcrumbs
      .map(breadcrumb => {
        return breadcrumb.categoryIdentifier
      })
      .join(',')
  }

  const pdpCommerceArgs = {
    externalId,
    breadcrumb: [categoryIdentifiers],
    previewDate,
    filterRulesLocaleOverride,
  }
  const skipPdpCommerce = !(externalId && categoryIdentifiers)

  const { data: dataPdpCommerce, isLoading: loadingCommercePlacements } = useGetPdpCommerceQuery(pdpCommerceArgs, {
    skip: skipPdpCommerce,
  })

  const placementsPdpCommerce = dataPdpCommerce?.commercePlacements || []

  const preFooter = find(placementsPdpCommerce, {
    name: 'footer_before_footer',
  })

  React.useEffect(() => {
    dispatch(setPreFooter(preFooter!))
  }, [preFooter])

  return {
    placementsPdpCommerce,
    loadingCommercePlacements,
  }
}
export default usePdpPlacements
