import styled from '@mui/material/styles/styled'

export const ContactLensesDataWrapper = styled('div', {
  name: 'ContactLensesData',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'disableAllFields',
})<{ disableAllFields: boolean }>(({ disableAllFields, theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 3fr 3fr',
  gap: theme.spacing(5),
  rowGap: theme.spacing(4),
  cursor: 'pointer',
  div: {
    pointerEvents: disableAllFields ? 'none' : 'inherit',
  },
}))

export const ContactLensesSelectPrescriptionTitle = styled('div', {
  name: 'ContactLensesData',
  slot: 'SelectPrescriptionTitle',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}))

export const ContactLensesInput = styled('input', {
  name: 'ContactLensesData',
  slot: 'Input',
})<{
  disabled: boolean
  readOnly: boolean
}>(({ theme, disabled, readOnly }) => ({
  height: '33px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '12px',
  backgroundColor: readOnly ? theme.palette.background.light.quinary : theme.palette.background.light.primary,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.custom.boulder}`,
  opacity: disabled ? '0.5' : '1',

  '&:read-only': {
    color: theme.palette.custom.light1.grey,
    backgroundColor: theme.palette.background.light.quinary,

    '&:focus': {
      outline: 'none',
    },
  },
}))
export const ContactLensesInputWrapper = styled('div', {
  name: 'ContactLensesData',
  slot: 'InputWrapper',
})(() => ({}))

export const ContactLensesDataColumn = styled('div', {
  name: 'ContactLensesData',
  slot: 'Column',
})(() => ({
  gridAutoRows: '1fr',
  display: 'grid',
  alignItems: 'center',
  rowGap: '20px',
}))

export const ContactLensesDataLabel = styled('span', {
  name: 'ContactLensesData',
  slot: 'Label',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: '0.75rem',
  fontWeight: '600',
  textTransform: 'uppercase',

  [theme.breakpoints.down('sm')]: {
    lineBreak: 'loose',
    hyphens: 'auto',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'elipsis',
    whiteSpace: 'break-spaces',
  },
}))

export const ContactLensesListHeadingContainer = styled('div', {
  name: 'ContactLensesData',
  slot: 'ListHeadingContainer',
  shouldForwardProp: props => props !== 'browserInUse',
})<{
  browserInUse?: string
}>(({ theme, browserInUse }) => ({
  backgroundColor: theme.palette.background.dark.secondary,
  display: 'flex',
  width: '100%',
  padding: '3px 0',
  position: 'relative',
  '& span': {
    maxWidth: browserInUse === 'Firefox' ? 'calc(50% - 6px)' : 'calc(50% - 2px)',
  },
}))

export const ContactLensesListHeadingItem = styled('span', {
  name: 'ContactLensesData',
  slot: 'ListHeading',
})(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  '&:nth-of-type(odd)': {
    borderRight: `1px solid ${theme.palette.background.dark.primary}`,
  },
}))

export const ContactLensesSeparator = styled('div', {
  name: 'ContactLensesData',
  slot: 'Separator',
})(({ theme }) => ({
  gridColumn: 'span 3',
  height: '1px',
  backgroundColor: theme.palette.background.light.tertiary,
}))

export const ClPricePerBoxWrapper = styled('span', {
  name: 'ContactLensesData',
  slot: 'ClItemPerBoxWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  fontSize: theme.typography.body2.fontSize,
  display: 'flex',
  justifyContent: 'end',
  '& > span': {
    marginRight: theme.spacing(1),
  },
}))
