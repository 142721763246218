import styled from '@mui/material/styles/styled'

export const ProductDetailsListWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,

  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(9.25)} 0`,
  },
}))

export const ProductDetailsListHeader = styled('div')({
  display: 'flex',
  alignSelf: 'stretch',
  flexGrow: 0,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 16,
  padding: 0,
})

export const HeaderTitle = styled('span')(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
}))

export const ProductDetailsListContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}))

export const ProductDetailsAttributeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

export const ProductDetailsAttributeValue = styled('span')(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
}))

export const ProductDetailsAttributeName = styled(ProductDetailsAttributeValue)(
  {
    fontWeight: 600,
    textTransform: 'uppercase',
  }
)

export const ProductDetailsDescriptionContainer = styled('span')(
  ({ theme }) => ({
    flexGrow: 0,
    padding: '0 1px 0 0',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.43,
    textAlign: 'left',
    color: theme.palette.text.dark.primary,
  })
)
