import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
// TYPES
import { Attachment } from '@typesApp/product'
//UI
import {
  ProductImageZoomWrapper,
  ProductImageZoomCloseIcon,
  ProductImageZoomMagnifiedImageWrapper,
  ProductImageZoomMagnifier,
} from './ProductImageZoom.style'
import ProductImagesThumbnails from '../ProductImagesThumbnails'
import { ProductZoomImagesSlider } from '../../ProductDetails.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

/**
 * @param { Attachment[] } images slider images
 * @param { boolean } isVisible toggle the component visibility
 * @param { boolean } pdpDataloading pdp data loading status
 * @param { function } onCloseClick callback when clicking on close button
 * @param { number } currentImageIndex you can force the image index
 * @param { number } zoomedImgSize zoomed image dimension
 * @param { number } lensWidth magnifier lens width
 * @param { number } lensHeight magnifier lens height
 */

export interface ProductImageZoomProps {
  alt?: string
  zoomedImgSize?: number
  images?: Attachment[]
  isVisible?: boolean
  onCloseClick?: () => void
  currentImageIndex?: number
  pdpDataloading?: boolean
  lensWidth?: number
  lensHeight?: number
}

const ProductImageZoom: FC<ProductImageZoomProps> = ({
  alt,
  images,
  onCloseClick,
  isVisible,
  lensWidth = 200,
  lensHeight = 200,
  currentImageIndex,
}: ProductImageZoomProps) => {
  const theme = useTheme()
  const largeImageRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [lensVisible, setLensVisible] = useState<boolean>(false)
  const [zoomedImageIndex, setZoomedImageIndex] = useState<number | undefined>(currentImageIndex)
  const [zoomVisible, setZoomVisible] = useState<boolean>(true)
  const [areImagesLoaded, setAreImagesLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (isVisible && currentImageIndex !== undefined) {
      setZoomedImageIndex(currentImageIndex)
    }
  }, [currentImageIndex, isVisible])

  /**
   * This ensures that the images are only loaded once
   * when the the gallery becomes visible.
   */
  useEffect(() => {
    if (isVisible && !areImagesLoaded) {
      setAreImagesLoaded(true)
    }
  }, [isVisible, areImagesLoaded])

  const getCursorPos = (e: MouseEvent<HTMLImageElement>) => {
    const img = e.currentTarget
    /*get the x and y positions of the image:*/
    const a = img.getBoundingClientRect()
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    const x = e.pageX - a.left
    const y = e.pageY - a.top
    return { x: x, y: y }
  }

  const moveLens = (e: MouseEvent<HTMLImageElement>) => {
    const pos = getCursorPos(e)
    /*calculate the position of the lens:*/

    const img = e.currentTarget
    let style = (largeImageRef.current && largeImageRef.current.style) || {}
    const imgWidth = img.width
    const imgHeight = img.height
    //calculate the ratio between visible image and true image size
    const zoomedImageRatio = img.naturalWidth / imgWidth
    let lensPositionX = pos.x - lensWidth / 2
    let lensPositionY = pos.y - lensHeight / 2

    /*prevent the lens from being positioned outside the image:*/
    if (lensPositionX > imgWidth - lensWidth) {
      lensPositionX = imgWidth - lensWidth
    }
    if (lensPositionX < 0) {
      lensPositionX = 0
    }
    if (lensPositionY > imgHeight - lensHeight) {
      lensPositionY = imgHeight - lensHeight
    }
    if (lensPositionY < 0) {
      lensPositionY = 0
    }

    // Set the background of the magnified image
    style['background'] = `url(${e.currentTarget.currentSrc}) no-repeat #ffffff `
    style['backgroundSize '] = imgWidth + 'px ' + imgHeight + 'px'
    style['backgroundPosition'] =
      '-' +
      (lensPositionX + lensWidth / 2 - 100 / zoomedImageRatio) * zoomedImageRatio +
      'px -' +
      (lensPositionY + lensWidth / 2 - 100 / zoomedImageRatio) * zoomedImageRatio +
      'px'
    // Move the magnifying glass with the mouse movement.
    style['left'] = lensPositionX + 'px'
    style['top'] = lensPositionY + 'px'
  }
  const onMouseHover = (e: MouseEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement
    moveLens(e)
    setLensVisible(!lensVisible)
    if (target && target.tagName.toLowerCase() === 'video') {
      setZoomVisible(false)
    } else {
      setZoomVisible(true)
    }
  }
  return (
    <ProductImageZoomWrapper isVisible={isVisible}>
      {areImagesLoaded && (
        <>
          <ProductImageZoomCloseIcon
            onClick={() => {
              onCloseClick && onCloseClick()
              setZoomedImageIndex(undefined)
            }}
          >
            <SVGIcon library="close" name="close" color={theme.palette.custom.cyprus} />
          </ProductImageZoomCloseIcon>
          <ProductImagesThumbnails
            images={images}
            currentIndex={zoomedImageIndex!}
            onThumbnailClick={index => {
              setZoomedImageIndex(index)
            }}
            thumbnailImageProps={{
              alt,
              usage: 'PDP',
              width: 80,
            }}
            isGalleryMode={isVisible}
          />
          <ProductImageZoomMagnifiedImageWrapper ref={wrapperRef}>
            {images && (
              <>
                <ProductZoomImagesSlider
                  currentSlide={zoomedImageIndex}
                  images={images}
                  sliderImageProps={{
                    alt,
                    onMouseMove: e => {
                      onMouseHover(e)
                    },
                    width: 2800,
                    usage: 'PDP',
                  }}
                  sliderProps={{
                    loop: false,
                  }}
                />
              </>
            )}
            {zoomVisible ? (
              <ProductImageZoomMagnifier ref={largeImageRef} lensWidth={lensWidth} lensHeight={lensHeight} />
            ) : (
              <></>
            )}
          </ProductImageZoomMagnifiedImageWrapper>
        </>
      )}
    </ProductImageZoomWrapper>
  )
}

export default ProductImageZoom
