import * as S from './ProductSize.style'

import React, { FC, useEffect, useState } from 'react'
import { ClusterProduct, IProduct, Size, Sizes } from '../../../types/product'
import SizeAdvisor, { SuggestedSizes } from '../../../utils/FrameGenius/SizeAdvisorUtil'
import { getBridgeWidth, getFrame, getHingeDistance, getMostSoldSize, getSize } from '../../../utils/productAttributes'
import { getCurrentCluster } from '../../../utils/product'
import theme from '../../../themes'
// import { useFrameGenius } from '../../../components/FrameGenius/FrameGeniusContext'
// i18n˙
import { useTranslation } from 'next-i18next'
import { useSite } from '../../../foundation/hooks/useSite'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { StyledSuggestedLabel } from './ProductSize.style'
import { SizeFitGuide } from './SizeFitGuide'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import clsx from 'clsx'
import styles from './styles/ProductSize.module.scss'

export interface ProductSizeProps {
  isLoading?: boolean
  productItems?: IProduct['items']
  onSizeChange: (id: string | undefined) => void
  currentProduct: IProduct
  cluster?: ClusterProduct[]
  partNumber?: IProduct['partNumber']
}

const ProductSize: FC<ProductSizeProps> = ({ isLoading, onSizeChange, currentProduct, cluster, partNumber }) => {
  const { t } = useTranslation()
  // const fgData = useFrameGenius()

  const currentSize = getSize(currentProduct)
  const mostSoldSize = getMostSoldSize(currentProduct)

  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
  const [selectedSize, setSelectedSize] = useState<string>(currentSize)
  const [sizesData, setSizesData] = useState<Size[]>([])

  const [sizeGuideOpen, setSizeGuideOpen] = useState(false)

  const [suggestedSizes, setSuggestedSizes] = useState<SuggestedSizes>({})

  const [isCurrentSizeRecommended, setCurrentSizeRecommendedStatus] = useState<boolean>(false)

  const handleOverlayOpen = () => setIsOverlayOpen(v => !v)

  const toggleSizeGuide = () => setSizeGuideOpen(v => !v)

  const getCurrentProductSizesData = (cluster: ClusterProduct[]): Size[] => {
    let itemSizes: Size[] = []
    const currentCluster = getCurrentCluster(partNumber || '', cluster)
    !!currentCluster?.length &&
      currentCluster[0].items?.map(item => {
        const size = getSize(item)
        const sizeOrder = Sizes[size]
        const frameSize = getFrame(item)
        const bridgeWidth = getBridgeWidth(item)
        const hingeDistance = getHingeDistance(item)
        if (typeof size === 'string') {
          itemSizes.push({
            size: size,
            frameSize,
            bridgeWidth,
            sizeOrder,
            uniqueID: item.uniqueID || '',
            hingeDistance,
          })
        }
        if (typeof size === 'object') {
          Object.entries(size).map(([k]) => {
            itemSizes.push({
              size: size[k],
              frameSize,
              bridgeWidth,
              sizeOrder,
              uniqueID: item.uniqueID || '',
              hingeDistance,
            })
          })
        }
      })

    return itemSizes
  }

  const getSortedSizesData = (productSizes: Size[]) => {
    return productSizes.sort((a, b) => a.sizeOrder - b.sizeOrder).filter(item => item.size)
  }

  useEffect(() => {
    cluster && setSizesData(getCurrentProductSizesData(cluster))
  }, [cluster])

  useEffect(() => {
    setSelectedSize(currentSize)
  }, [currentSize])

  const handleSizeChange = (sizeInfo: Size) => {
    onSizeChange(sizeInfo.uniqueID)
    setSelectedSize(sizeInfo.size)
    handleOverlayOpen()
  }

  const updateCurrentSizeRecommendeStatus = () => {
    const sizeEnumIndex = sizesData.find(size => size?.size === currentSize)?.sizeOrder ?? 0
    const mappedCurrentSize = { label: Sizes[sizeEnumIndex] }
    const isCurrentSizeRecommended =
      !!suggestedSizes && SizeAdvisor.isSizeRecommended(mappedCurrentSize, suggestedSizes)
    setCurrentSizeRecommendedStatus(isCurrentSizeRecommended)
  }

  const handleFrameGeniusAnalysisResults = () => {
    // const mappedSuggestedSizes = SizeAdvisor.mapSuggestedSizes(
    //   sizesData,
    //   fgData.analysisResults
    // )
    // mappedSuggestedSizes && setSuggestedSizes(mappedSuggestedSizes)
    // updateCurrentSizeRecommendeStatus()
    // const recommendedSize =
    //   mappedSuggestedSizes &&
    //   getSortedSizesData(sizesData).find((s) =>
    //     SizeAdvisor.isSizeRecommended({ label: s.size }, mappedSuggestedSizes)
    //   )
    // if (recommendedSize) {
    //   setSelectedSize(recommendedSize.size)
    //   recommendedSize.uniqueID !== currentProduct.uniqueID &&
    //     onSizeChange(recommendedSize.uniqueID)
    // }
  }

  useEffect(() => {
    const sortedCurrentProductSizes = getSortedSizesData(getCurrentProductSizesData(cluster || []))
    const initialProductSize = mostSoldSize
      ? (sortedCurrentProductSizes.find(product => +product.frameSize === +mostSoldSize) as Size)
      : sortedCurrentProductSizes[0]

    setSelectedSize(initialProductSize?.size)

    // TODO: on mounted it looks like that we are not correctly processing analysis labels. Investigate this point
    handleFrameGeniusAnalysisResults()
  }, [])

  // useEffect(() => {
  //   if (sizesData && fgData.analysisResults) {
  //     handleFrameGeniusAnalysisResults()
  //   }
  // }, [fgData.analysisResults, sizesData])

  // useEffect(() => {
  //   const analysisResultsSize = fgData?.analysisResults?.size?.label

  //   analysisResultsSize &&
  //     !Object.keys(suggestedSizes).length &&
  //     setSuggestedSizes({ [analysisResultsSize]: SizeAdvisorKeys.RECOMMENDED })
  // }, [])

  useEffect(() => {
    updateCurrentSizeRecommendeStatus()
  }, [selectedSize])

  const { mySite } = useSite()
  const frameGeniusStoreconfs = mySite.xStoreCfg.frameGenius
  const isSizeAdvisorEnabled = frameGeniusStoreconfs?.isSizeAdvisorEnabled || false

  if (isLoading) {
    return (
      <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
        <S.StyledSizeLabel>
          <Skeleton style={{ borderRadius: 0 }} />
        </S.StyledSizeLabel>
      </SkeletonTheme>
    )
  }

  return (
    <S.ProductSizeWrapper>
      <S.StyledSizeLabel>{t('ProductAvailableNotificationDrawer.Labels.Size').toUpperCase()}</S.StyledSizeLabel>
      {isCurrentSizeRecommended && (
        <S.StyledRecommendedSizeLabel>
          {t('ProductAvailableNotificationDrawer.Labels.Recommended').toUpperCase()}
        </S.StyledRecommendedSizeLabel>
      )}
      <S.StyledSizeCtaWrapper onClick={handleOverlayOpen}>
        <S.StyledSizeCtaOverlay>{isCurrentSizeRecommended ? selectedSize : currentSize}</S.StyledSizeCtaOverlay>
        <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
      </S.StyledSizeCtaWrapper>
      {sizeGuideOpen && <SizeFitGuide onBackdropClick={toggleSizeGuide} sizeGuideOpen={sizeGuideOpen} />}
      <S.StyledSizeDrawer open={isOverlayOpen} onBackdropClick={handleOverlayOpen} anchor="right">
        <S.StyledSizeDrawerContainer>
          <S.StyledSizeDrawerHeader>
            {t('ProductAvailableNotificationDrawer.Labels.Size').toUpperCase()}
            <button
              type="button"
              aria-label="Close"
              title="Close"
              className={styles.closeButton}
              onClick={handleOverlayOpen}
            >
              <SVGIcon library="close" name="close" />
            </button>
          </S.StyledSizeDrawerHeader>
          {getSortedSizesData(sizesData)?.map(sizeInfo => {
            const isSelected = sizeInfo.uniqueID === currentProduct.uniqueID

            const suggestionLabel = SizeAdvisor.mapSuggestionLabel(sizeInfo.sizeOrder, suggestedSizes)
            const isSuggestionLabelRecommended = suggestionLabel === 'Recommended'
            return (
              <S.StyledSizeItemContainer
                selected={isSelected}
                key={sizeInfo.uniqueID}
                onClick={() => handleSizeChange(sizeInfo)}
              >
                {`${sizeInfo.size} (${sizeInfo.frameSize}-${sizeInfo.bridgeWidth})`}
                {!!suggestionLabel && (
                  <StyledSuggestedLabel isRecommended={isSuggestionLabelRecommended} fontSize={10} marginLeft={2.5}>
                    {isSuggestionLabelRecommended && (
                      <SVGIcon library="global" name="frame-genius" color={theme.palette.custom.cyprus} />
                    )}
                    {t(`ProductAvailableNotificationDrawer.Labels.${suggestionLabel}`)}
                  </StyledSuggestedLabel>
                )}
                {isSelected ? (
                  <SVGIcon library="validation" name="checkmark-filled-mc" className={clsx(styles.checkmarkIcon)} />
                ) : null}
              </S.StyledSizeItemContainer>
            )
          })}
          {isSizeAdvisorEnabled && (
            <S.StyledFindMySize>
              {t('ProductAvailableNotificationDrawer.Labels.FindMySize')}

              <S.StyledIconFrameSize>
                <SVGIcon library="global" name="frame-genius" color={theme.palette.custom.halfBaked} />
                <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
              </S.StyledIconFrameSize>
            </S.StyledFindMySize>
          )}
          <S.StyledSizeGuideLink onClick={toggleSizeGuide}>
            {t('ProductAvailableNotificationDrawer.Labels.learnMore')}
          </S.StyledSizeGuideLink>
        </S.StyledSizeDrawerContainer>
      </S.StyledSizeDrawer>
    </S.ProductSizeWrapper>
  )
}

export default ProductSize
