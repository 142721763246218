import styled from '@mui/material/styles/styled'
import Slider from '@mui/material/Slider'

import { Accordion, AccordionSummary, Button, IconButton, StyledButtonAsLink } from '../UI'
import { Z_INDEX_LV2 } from '../../constants/ui'
import { Drawer } from '@mui/material'

export const StyledCloseIconButtonContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'IconButtonContainer',
})(() => ({
  textAlign: 'right',
}))

export const StyledFiltersContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  flexGrow: 0,
  padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  gap: theme.spacing(4),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}))

export const StyledPLPFilteredContent = styled('div', {
  name: 'PlpFilter',
  slot: 'PLPFilteredContent',
})(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}))

export const StyledFiltersHeader = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersHeader',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.background.dark.primary,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: Z_INDEX_LV2,
}))

export const StyledFiltersDrawerContent = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersDrawerContent',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: 375,
  overflowY: 'auto',

  [theme.breakpoints.up('sm')]: {
    width: 371,
  },
  [theme.breakpoints.down('xs')]: {
    width: 350,
  },
}))

export const StyledFiltersFooter = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersFooter',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.background.light.primary,
  display: 'flex',
  position: 'sticky',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 -5px 20px 0 rgba(0, 0, 0, 0.05)',
  bottom: 0,
  zIndex: Z_INDEX_LV2,
}))

export const StyledFiltersHeaderTitle = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersHeaderTitle',
})(({ theme }) => ({
  color: theme.palette.text.light.tertiary,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.43,
}))

export const StyledFiltersSectionTitle = styled('div', {
  name: 'PlpFilter',
  slot: 'FiltersSectionTitle',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 1.43,
}))

export const StyledSuggestedFiltersContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'SuggestedFiltersContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  padding: theme.spacing(4),
}))

export const StyledSuggestedFiltersContent = styled('div', {
  name: 'PlpFilter',
  slot: 'SuggestedFiltersContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
}))

export const StyledAccordion = styled(Accordion, {
  name: 'PlpFilter',
  slot: 'Accordion',
})(() => ({
  textTransform: 'uppercase',
}))

export const StyledAccordionSummary = styled(AccordionSummary, {
  name: 'PlpFilter',
  slot: 'AccordionSummary',
})(({ theme }) => ({
  padding: `0 ${theme.spacing(6.275)} 0 ${theme.spacing(4)}`,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 1.43,
  color: theme.palette.text.dark.primary,
  borderTop: `1px solid ${theme.palette.background.light.tertiary}`,
  borderBottom: 'none',
  '& .MuiAccordionSummary-content': {
    overflowX: 'hidden',
  },
}))

export const StyledFilterNotes = styled('div', {
  name: 'PlpFilter',
  slot: 'FilterNotes',
})(({ theme }) => ({
  color: theme.palette.text.light.secondary,
  fontSize: 10,
  textTransform: 'none',
  margin: theme.spacing(-1, 4, 4, 4),
}))

export const ClearAllStyledButtonAsLink = styled(StyledButtonAsLink, {
  name: 'PlpFilter',
  slot: 'ClearAll',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  textAlign: 'center',
}))

export const StyledFiltersDrawer = styled(Drawer, {
  name: 'PlpFilter',
  slot: 'FilterDrawer',
})(() => ({
  height: '100vh',
}))

export const SeeResultsStyledButton = styled(Button, {
  name: 'PlpFilter',
  slot: 'SeeResults',
})(({ theme }) => ({
  padding: 0,
  width: 'auto',
  '& .content': {
    lineHeight: 1.43,
    backgroundColor: theme.palette.background.dark.primary,
    color: theme.palette.text.light.tertiary,
    minWidth: 120,
    '& .content-label': {
      margin: '8px 10px',
    },
  },
}))

export const SearchInputWrapper = styled('div', {
  name: 'PlpFilter',
  slot: 'SearchInputWrapper',
})(({ theme }) => ({
  margin: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.background.light.secondary,
  height: '58px',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.spacing(),
}))

export const SearchInput = styled('input', {
  name: 'PlpFilter',
  slot: 'SearchInput',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  paddingLeft: theme.spacing(4),
  border: 'none',
  flex: 1,
  ':focus': {
    outline: 'none',
  },
  fontSize: 14,
}))

export const SearchInputIconWrapper = styled('div', {
  name: 'PlpFilter',
  slot: 'SearchInputIconWrapper',
})(({ theme }) => ({
  marginRight: theme.spacing(5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const CloseIconButton = styled(IconButton, {
  name: 'PlpFilter',
  slot: 'CloseIconButton',
})(({ theme }) => ({
  color: theme.palette.text.light.tertiary,
}))

export const RangeInputWrapper = styled('div', {
  name: 'PlpFilter',
  slot: 'RangeInputWrapper',
})(({ theme }) => ({
  margin: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
  height: '58px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0,6)
  },
}))

export const RangeInput = styled(Slider, {
  name: 'PlpFilter',
  slot: 'RangeInput',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  width: '100%',
  margin: '0px 10px',
}))

export const RangeTextInput = styled('input', {
  name: 'PlpFilter',
  slot: 'RangeTextInput',
})(({ theme }) => ({
  width: '20%',
  height: '38px',
  color: theme.palette.text.dark.primary,
  backgroundColor: theme.palette.background.light.secondary,
}))

export const SizeAdvisorFilterContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'SizeAdvisorContainer',
})(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const SizeAdvisorButton = styled('div', {
  name: 'PlpFilter',
  slot: 'SizeAdvisorButton',
})(({ theme }) => ({
  lineHeight: 1.43,
  fontWeight: 'bold',
  fontSize: theme.spacing(3.5),
  textDecoration: 'underline',
  color: theme.palette.text.dark.primary,
  marginLeft: theme.spacing(2),
  textTransform: 'capitalize',
}))

export const SizeAdvisorButtonContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'SizeAdvisorButtonContainer',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}))
