import styled from '@mui/material/styles/styled'
import ProductImage from '../../../../components/ProductImage/ProductImage'

import { Button, StyledAnchor, StyledFormControlLabel } from '../../../../components/UI'

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 375,
  },
}))

export const Header = styled('div')(({ theme }) => ({
  background: theme.palette.background.dark.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
}))

export const Title = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.light.primary,
  fontWeight: 600,
  lineHeight: 1.43,
  letterSpacing: 'normal',
  textTransform: 'uppercase',
}))

export const Content = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.dark.tertiary,
  padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
}))

export const Image = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
  height: 'auto',
}))

export const AttributeLabel = styled('span')(() => ({
  fontWeight: 'bold',
}))

export const AttributesContent = styled('div')(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(10),
  color: theme.palette.text.dark.primary,
}))

export const PrivacyPolicyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  color: theme.palette.text.light.secondary,
  lineHeight: 1.33,
  fontSize: 12,
  marginBottom: theme.spacing(6),
}))

export const PrivacyLink = styled(StyledAnchor)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light.secondary,
}))

export const SubscribeButton = styled(Button)(() => ({ marginLeft: 0 }))

export const CloseSubscribeButton = styled(Button)(() => ({}))

export const Description = styled('div')(({ theme }) => ({ color: theme.palette.text.dark.primary }))

export const DescriptionSuccess = styled(Description)(() => ({ fontSize: '1.5rem' }))

export const DescriptionFailure = styled(Description)(() => ({ fontSize: '1rem', color: 'red' }))

export const CheckboxContainer = styled('div', {
  name: 'ProductNotificationDrawer',
  slot: 'CheckboxContainer',
})(({ theme }) => ({
  fontSize: 14,
  display: 'flex',
  margin: `${theme.spacing(6)} 0`,
  verticalAlign: 'flex-start',
  '> label': {
    display: 'flex',
    alignItems: 'flex-start',
  },
}))

export const StyledOOSFormControlLabel = styled(StyledFormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-root': {
    display: 'flex',
  },
  '.MuiFormControlLabel-label': {
    maxWidth: 'none',
    fontSize: 14,
    marginRight: 0,
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}))

export const StyledProductImage = styled(ProductImage)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}))
