import styled from '@mui/material/styles/styled'
import { StyledAnchor } from '../../../components/UI'

export const SizeSwitcherContainer = styled('div', {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherContainer',
})(({ theme }) => ({
  width: 'fit-content',
  minWidth: 160,
  backgroundColor: theme.palette.custom.wildSand,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.spacing(4),
  height: 24,
}))

export const SizeSwitcherOption = styled(StyledAnchor, {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherOption',
})<{
  href: string
}>(({ theme }) => ({
  fontSize: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // flex: 1,
  width: 80,
  color: theme.palette.text.light.secondary,
  fontWeight: 600,
  textTransform: 'uppercase',
  cursor: 'pointer',
  border: 'none',
  borderRadius: theme.spacing(4),
  textDecoration: 'none',
  '&:after': {
    height: 0,
  },
}))

export const SizeSwitcherOptionSelected = styled('div', {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherOptionSelected',
})(({ theme }) => ({
  fontSize: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // flex: 1,
  width: 80,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.text.dark.primary,
  border: `1px solid ${theme.palette.text.light.tertiary}`,
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.background.light.primary,
  textDecoration: 'none',
}))
