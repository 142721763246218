import React from 'react'

import { useTranslation } from 'next-i18next'

import { useGetMegaMenuQuery } from '../../../features/cms/query'
import { IContentMegaMenu } from '../../../types/cms'
import { ProductType } from '../../../types/product'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { PRODUCT_TYPES_MAP } from '../../../constants/product'

import {
  SoldOutDescription,
  SoldOutImagePlaceholderContainer,
  SoldOutTitle,
  StyledSoldOutCTA,
} from './ProductSoldoutBanner.style'
import { isGlassesOrSunglasses } from '../../../utils/product'
import { useSearchParams } from 'next/navigation'

type Props = {
  breadcrumbs: any[]
  basePath?: any[]
  productType: ProductType | null
}

const getProductCateogryUrl = (contentMegaMenu: IContentMegaMenu[], productType?: ProductType | null): string => {
  const content = contentMegaMenu?.find(item => {
    switch (productType) {
      case PRODUCT_TYPES_MAP.optical:
      case PRODUCT_TYPES_MAP.frames:
        return item.name.toLowerCase() === 'glasses'
      case PRODUCT_TYPES_MAP.sun:
        return item.name.toLowerCase() === 'sunglasses'
      default:
        return null
    }
  })

  const placements = content?.placements ?? []
  const placement = placements?.find(p => p.viewtype === 'categories-with-icon')
  const teaserItem = placement?.items?.find(isLXTeaser)
  const actionSettings = teaserItem?.teaserLXCallToActionSettings || []
  return actionSettings[0]?.target.formattedUrl
}

const ProductSoldoutBanner = ({ breadcrumbs, productType }: Props) => {
  const { t } = useTranslation()
  const skipQuery = !isGlassesOrSunglasses(productType)
  const searchParams = useSearchParams()
  const previewDate = searchParams.get('previewDate')
  const filterRulesLocaleOverride = searchParams.get('filterRulesLocaleOverride')

  const { data } = useGetMegaMenuQuery(
    {
      ...(previewDate && { previewDate }),
      ...(filterRulesLocaleOverride && { filterRulesLocaleOverride }),
    },
    {
      skip: skipQuery,
    }
  )
  const contentMegaMenu = data?.contentMegaMenu || []
  const categoryUrl = getProductCateogryUrl(contentMegaMenu, productType)
  const redirectCTA = categoryUrl ?? (breadcrumbs && breadcrumbs?.[0]?.seo?.href) ?? ''

  return (
    <SoldOutImagePlaceholderContainer>
      <SoldOutTitle>{t('SoldOut.Sorry')}</SoldOutTitle>
      <SoldOutDescription>{t('SoldOut.SoldOutMessage')}</SoldOutDescription>

      <StyledSoldOutCTA href={redirectCTA || ''}>
        {t(`SoldOut.CTA.ViewSimilarProducts${'-' + productType}`, t('SoldOut.CTA.ViewSimilarProducts'))}
      </StyledSoldOutCTA>
    </SoldOutImagePlaceholderContainer>
  )
}

export default ProductSoldoutBanner
