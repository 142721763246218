import styled from '@mui/material/styles/styled'
import { Button } from '../../../components/UI'

export const BrandTitle = styled('h5', {
  name: 'PSPStickBar',
  slot: 'BrandTitle',
})(({theme}) => ({
  textTransform: 'uppercase',
  margin: 0,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.h4.fontSize,
  fontWeight: 600
}))
export const ModelName = styled('h6', {
  name: 'PSPStickBar',
  slot: 'ModelName',
})(({theme}) => ({
  margin: 0,
  fontSize: theme.typography.subtitle1.fontSize,
  lineHeight: theme.typography.body1.fontSize,
  fontWeight: 'normal'
}))

export const StickyBarCtaContainer = styled('div', {
  name: 'PSPStickBar',
  slot: 'CtaContainer',
})(({ theme }) => ({
  alignItems: 'center',
  padding: '1rem 0',
  width: '100%',
  display: 'flex',
  [theme.breakpoints.down(331)]: {
    'button': {
      fontSize: 14
    }
  },
}))

export const StickyBarCta = styled(Button)(() => ({}))
