/* eslint complexity: off */
import { INDICES_MAP } from '@features/plp/algoliaUtils'
import { initIndexName } from '@foundation/algolia/algoliaConfig'
import type { UiState } from 'instantsearch.js'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers'

export type RouteState = {
  query?: string
  page?: number
  range?: Record<string, string>
  refinementList?: Record<string, string[]>
  menu?: Record<string, string>
  priceFrom?: string
  priceTo?: string
  discount?: string
  sortBy?: string
}

export const getFacetsFromRouteState = (routeState: RouteState) => {
  const facets: Record<string, string[]> = {}

  Object.keys(routeState).forEach(key => {
    if (key.includes('attributes_translated')) {
      facets[key] = routeState[key] as string[]
    }
  })

  return facets
}

const getFilteredRefinementList = (refinementList: Record<string, string[]>) => {
  const filteredRefinementList: Record<string, string[]> = {}

  Object.keys(refinementList).forEach(key => {
    if (key.includes('attributes_translated')) {
      filteredRefinementList[key] = refinementList[key]
    }
  })

  return filteredRefinementList
}

const discountRouteToState = (
  menu:
    | {
        [attribute: string]: string
      }
    | undefined
) => {
  const discountRefinement: Record<string, string | undefined> = {}

  Object.keys(menu || {}).forEach(key => {
    if (key.includes('sortDiscount_')) {
      const value = menu?.[key]
      discountRefinement['discount'] = value
    }
  })

  return discountRefinement
}

const getPricesRange = (
  range: Record<string, string> | undefined,
  customerSegment: string
): { priceFrom?: string | undefined; priceTo?: string | undefined } => {
  if (!range) return {}
  const priceRange = range[`sortPrice_${customerSegment}`]?.split(':')

  if (!Array.isArray(priceRange)) return {}

  return {
    priceFrom: priceRange[0],
    priceTo: priceRange[1],
  }
}

const creatPriceRangeState = (
  priceFrom: string | undefined,
  priceTo: string | undefined,
  customerSegment: string
): { [key: string]: string } => {
  if (!priceFrom && !priceTo) return {}
  return {
    [`sortPrice_${customerSegment}`]: `${priceFrom}:${priceTo}`,
  }
}

const router = (serverUrl: string, customerSegment: string): ReturnType<typeof historyRouter<RouteState>> =>
  historyRouter<RouteState>({
    getLocation() {
      if (typeof window === 'undefined') {
        return new URL(serverUrl) as unknown as Location
      }

      return window.location
    },
    createURL({ qsModule, routeState, location }): string {
      const { protocol, hostname, port = '', pathname, hash } = location

      const portWithPrefix = port === '' ? '' : `:${port}`

      const baseUrl = `${protocol}//${hostname}${portWithPrefix}${pathname}`

      const locationParameters = {}

      Object.entries(qsModule.parse(location.search.slice(1)) || {}).forEach(([key, value]) => {
        const isAlgoliaKey = key.includes('sortDiscount_') ||
                             key.includes('attributes_translated') ||
                             key.includes('sort_by') ||
                             key.includes('priceFrom') ||
                             key.includes('priceTo') ||
                             key.includes('discount') ||
                             key.includes('page')
        if (!isAlgoliaKey) {
          locationParameters[key] = value
        }
      })

      const queryParameters: Partial<any> = {
        ...locationParameters,
        ...getFacetsFromRouteState(routeState)
      }

      if (routeState.query) {
        queryParameters.query = encodeURIComponent(routeState.query)
      }

      if (routeState.page) {
        queryParameters.page = routeState.page
      }

      if (routeState.priceFrom || routeState.priceTo) {
        queryParameters.priceFrom = routeState.priceFrom
        queryParameters.priceTo = routeState.priceTo
      }

      if (routeState.sortBy) {
        queryParameters.sortBy = routeState.sortBy
      }

      if (routeState.discount) {
        queryParameters.discount = routeState.discount
      }

      const queryString = qsModule.stringify(queryParameters, {
        addQueryPrefix: true,
        indices: false,
        arrayFormat: 'repeat',
        encode: true,
      })

      return `${baseUrl}${queryString}${hash}`
    },
    parseURL({ qsModule, location }) {
      const queryParameters = qsModule.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      // `qs` does not return an array when there's a single value.
      for (const key in queryParameters) {
        if (key.includes('attributes_translated') && !Array.isArray(queryParameters[key])) {
          queryParameters[key] = [queryParameters[key] as string]
        }
      }

      const { query = '', page = 0, sortBy = 0, priceFrom, priceTo, discount, ...rest } = queryParameters

      const range = creatPriceRangeState(priceFrom as string, priceTo as string, customerSegment)

      return {
        query: decodeURIComponent(query as string),
        page: (page as number) ?? Number(page) - 1,
        sortBy: sortBy as string,
        range,
        menu: discount
          ? {
              [`sortDiscount_${customerSegment}`]: discount as string,
            }
          : undefined,
        refinementList: {
          ...getFacetsFromRouteState(rest),
        },
      }
    },
  })

const getStateMapping = ({
  indexName,
  customerSegment,
  locale,
}: {
  indexName: string
  customerSegment: string
  locale: string
}) => ({
  stateToRoute(uiState: UiState): RouteState {
    const indexUiState = uiState[indexName]
    const priceRange = getPricesRange(indexUiState.range, customerSegment)

    const refinementList = indexUiState.refinementList ? getFilteredRefinementList(indexUiState.refinementList) : {}
    indexUiState.menu
    const discount = discountRouteToState(indexUiState.menu)

    const sortIndexes = Object.values(INDICES_MAP).reduce((acc, index) => {
      acc[initIndexName({ locale, sortOption: index, customerSegment })] = index
      return acc
    }, {})

    const sortIndex = sortIndexes[indexUiState.sortBy as string]

    return {
      query: indexUiState.query,
      page: indexUiState.page,
      ...priceRange,
      sortBy: sortIndex,
      ...refinementList,
      ...discount,
    }
  },

  routeToState(routeState: RouteState): UiState {
    const sortByIndexName = routeState.sortBy
      ? initIndexName({ locale, sortOption: Number(routeState.sortBy), customerSegment })
      : initIndexName({ locale, sortOption: INDICES_MAP.BEST_SELLERS, customerSegment })

    return {
      [indexName]: {
        page: routeState.page,
        query: routeState.query,
        range: routeState.range,
        refinementList: routeState.refinementList,
        sortBy: sortByIndexName,
        menu: routeState.menu,
        configure: {
          attributesToRetrieve: [
            Number(routeState.sortBy) === INDICES_MAP.PRICE_ASC ||
            Number(routeState.sortBy) === INDICES_MAP.PRICE_DESC ||
            routeState.menu
              ? '*'
              : 'x_groupkey',
          ],
        },
      },
    }
  },
})

const getRouting = (
  indexName: string,
  serverUrl: string,
  customerSegment: string,
  locale: string
): {
  router: ReturnType<typeof router>
  stateMapping: ReturnType<typeof getStateMapping>
} => {
  return {
    router: router(serverUrl, customerSegment),
    stateMapping: getStateMapping({ indexName, customerSegment, locale }),
  }
}

export default getRouting
