import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import {
  CustomRadioProps,
  StyledInternalRadio,
  StyledRadioCheckedIconWrapper,
  StyledRadioIcon,
} from '@components/UI/Checkbox/StyledRadio'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const SubscriptionStyledRadio = React.forwardRef<any, CustomRadioProps>((props: any, _ref: any) => {
  const { error, disabled, ...other } = props
  const theme = useTheme()
  return (
    <StyledInternalRadio
      icon={<StyledRadioIcon className={error ? 'errorIcon' : ''} />}
      disableRipple
      checkedIcon={
        <StyledRadioCheckedIconWrapper
          color={theme.palette.text.dark.primary}
          borderColor={theme.palette.text.dark.primary}
          theme={theme}
        >
          <SVGIcon
            library="global"
            name="checked-radio"
            color={disabled ? theme.palette.custom.light2.grey : theme.palette.text.dark.primary}
          />
        </StyledRadioCheckedIconWrapper>
      }
      disabled={disabled}
      error={error}
      {...other}
    />
  )
})
