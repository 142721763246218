/**
 * Sorts an array of objects by key.
 * By default, it sorts the array in ascending order unless 'direction' is defined.
 * @param array
 * @param key
 * @param direction
 * @returns array
 */
export const sortByKey = (array: Record<string, any>[], key: string, direction?: 'asc' | 'desc') => {
  if (!array?.length || !key) return []

  const dir = direction ?? 'asc'
  const getValue = (obj, prop) => {
    const parts = prop.split('.')
    return parts.reduce((a, v) => a[v], obj)
  }

  return array?.sort((a, b) => {
    const aValue = getValue(a, key)
    const bValue = getValue(b, key)

    if (dir === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
    } else {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0
    }
  })
}

/**
 * Splits an array into two arrays based on a condition.
 * The first array contains elements that satisfy the condition,
 * and the second array contains the remaining elements.
 * @param array
 * @param validate
 * @returns [array1, array2]
 */
export const partition = (array: any[], validate: (props) => boolean) => {
  return array.reduce(
    ([pass, fail], elem) => {
      return validate(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]
    },
    [[], []]
  )
}
